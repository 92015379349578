import { Box, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { Avatar } from 'src/components/Avatar';
import { AVATAR_SHADOW, AVATAR_SIZE, AVATAR_VARIANT } from 'src/components/Avatar/types';
import { Button } from 'src/components/Button';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import colors from 'src/theme/base/colors';
import { useFacebookPosts } from 'src/hooks/useFacebookPosts';
import boxShadows from 'src/theme/base/boxShadows';
import { ScheduleFacebookPostStep1Props } from './types';
import { FacebookPost } from 'src/DTO/FacebookPost.type';

export const ScheduleFacebookPostStep1: React.FC<ScheduleFacebookPostStep1Props> = ({
  fbPosts,
  onCancel,
  selectedJob,
  getFbPostLoading,
  generatedText,
  generateFbPost,
  imageLoading,
  textLoading,
  setImageLoading,
  setTextLoading,
  setFbPosts,
  handleMoveToNextStep,
}) => {
  const { updateFacebookPost } = useFacebookPosts();

  const [previousFbPosts, setPreviousFbPosts] = useState<FacebookPost[]>([]);
  const [isEditing, setIsEditing] = useState<boolean[]>([false]);

  const toggleEditing = (index: number, isEditing: boolean) => {
    setIsEditing(prev => prev?.map((value, i) => (i === index ? isEditing : value)));
  };

  const handleSubmit = () => {
    handleMoveToNextStep();
  };

  useEffect(() => {
    if (generatedText) {
      setPreviousFbPosts(prev =>
        prev?.map(post => ({
          ...post,
          postText: generatedText,
        })),
      );
    }
  }, [generatedText]);

  const startEditing = (index: number, postId: string) => {
    setPreviousFbPosts(prevFbPost => {
      return !prevFbPost?.some(post => post.id === postId) && fbPosts
        ? [...prevFbPost, fbPosts[index]]
        : prevFbPost;
    });
    toggleEditing(index, true);
  };

  const cancelEditing = (index: number, postId: string) => {
    setPreviousFbPosts(prevFbPost => {
      const originalPost = prevFbPost?.find(prev => prev?.id === postId);
      if (originalPost) {
        setFbPosts(currentPosts =>
          currentPosts?.map(post => (post?.id === postId ? originalPost : post)),
        );
      }
      return prevFbPost;
    });
    toggleEditing(index, false);
  };

  const updatePostTexts = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    postId: string,
  ) => {
    setFbPosts(
      fbPosts.map(post => {
        return post?.id === postId ? { ...post, postText: e?.target?.value } : post;
      }),
    );
  };

  const saveChanges = (index: number, postId: string) => {
    toggleEditing(index, false);

    const currentPost = fbPosts?.find(post => post?.id === postId);

    if (currentPost)
      setPreviousFbPosts(
        previousFbPosts.map(post => {
          return post?.id === postId ? currentPost : post;
        }),
      );

    updateFacebookPost({
      variables: {
        updatedFacebookPost: {
          id: postId,
          postText: currentPost?.postText,
        },
      },
    });
  };

  const handleGenerate = (shouldGenerateText: boolean, shouldGenerateImage: boolean) => {
    setTextLoading(shouldGenerateText);
    setImageLoading(shouldGenerateImage);

    if (selectedJob) {
      generateFbPost({
        variables: { jobId: selectedJob.id, shouldGenerateText, shouldGenerateImage },
      });
    }
  };

  const isLoading = getFbPostLoading || textLoading || imageLoading;

  if (getFbPostLoading) {
    return (
      <Grid item xs={12}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      {fbPosts?.length > 0 ? (
        <Grid item xs={12}>
          <Box mb={4} display="flex" justifyContent="center">
            <Typography variant="h5">
              <Trans>Módosítsd tetszés szerint a generált Facebook posztok szövegét</Trans>
            </Typography>
          </Box>

          {fbPosts?.map((fbPost, index) => (
            <Grid item xs={12} key={fbPost.id}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                flexDirection="column"
                mb={4}>
                <Box
                  width="100%"
                  border={`2px solid ${colors.inputColors.borderColor.main}`}
                  borderRadius={3}
                  boxShadow={boxShadows.sm}
                  sx={{
                    bgcolor: colors.grey[100],
                    p: 2,
                  }}>
                  <Box
                    border={`2px solid ${colors.inputColors.borderColor.main}`}
                    borderRadius={3}
                    sx={{
                      p: 2,
                      fontSize: 13,
                      bgcolor: 'white !important',
                      lineHeight: 1.5,
                      '&:hover': {
                        borderColor: isEditing[index]
                          ? colors.inputColors.borderColor.focus
                          : colors.inputColors.borderColor.main,
                      },
                    }}>
                    {textLoading ? (
                      <CircularProgress size={12} color="inherit" />
                    ) : (
                      <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        inputProps={{
                          style: {
                            minWidth: '100%',
                            maxHeight: '100%',
                            fontSize: 13,
                            lineHeight: 1.5,
                            borderRadius: 0,
                          },
                          readOnly: !isEditing[index],
                        }}
                        sx={{
                          '.MuiOutlinedInput-root': {
                            border: 0,
                            padding: '0 !important',
                            margin: 0,
                          },
                          '.MuiInputBase-root': {
                            backgroundColor: 'white !important',
                            padding: '0 !important',
                            margin: 0,
                          },
                        }}
                        minRows={1}
                        value={fbPost?.postText}
                        onChange={e => updatePostTexts(e, fbPost.id)}
                      />
                    )}
                  </Box>

                  <Box mt={2} display="flex" gap={1}>
                    {!isEditing[index] ? (
                      <Button
                        variant={BUTTON_VARIANT.GRADIENT}
                        color={BUTTON_COLOR.DARK}
                        disabled={isLoading}
                        onClick={() => startEditing(index, fbPost.id)}>
                        <Trans>Szerkesztés</Trans>
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant={BUTTON_VARIANT.CONTAINED}
                          color={BUTTON_COLOR.DARK}
                          disabled={
                            previousFbPosts?.[index]?.postText?.trim() === fbPost?.postText?.trim()
                          }
                          onClick={() => saveChanges(index, fbPost.id)}>
                          <Trans>Mentés</Trans>
                        </Button>
                        <Button
                          variant={BUTTON_VARIANT.CONTAINED}
                          color={BUTTON_COLOR.DARK}
                          onClick={() => cancelEditing(index, fbPost.id)}>
                          <Trans>Mégsem</Trans>
                        </Button>
                      </>
                    )}
                    <Button
                      variant={BUTTON_VARIANT.GRADIENT}
                      color={BUTTON_COLOR.DARK}
                      disabled={isLoading}
                      type="submit"
                      onClick={() => handleGenerate(true, false)}>
                      {textLoading ? (
                        <CircularProgress size={12} color="inherit" />
                      ) : (
                        <Trans>Szöveg újragenerálása</Trans>
                      )}
                    </Button>
                  </Box>

                  <Box display="flex" justifyContent="right">
                    {imageLoading ? (
                      <CircularProgress size={12} color="inherit" />
                    ) : (
                      <Avatar
                        alt="Generated image"
                        src={fbPost.postPictureUrl}
                        variant={AVATAR_VARIANT.ROUNDED}
                        size={AVATAR_SIZE.XXL}
                        shadow={AVATAR_SHADOW.SM}
                        onClick={() => {
                          window.open(fbPost.postPictureUrl, '_blank', 'noopener noreferrer');
                        }}
                        sx={{
                          cursor: 'pointer',
                          userSelect: 'none',
                          transition: 'transform 0.4s',
                          '&:hover': {
                            transform: 'scale(1.04)',
                          },
                        }}
                      />
                    )}
                  </Box>
                  <Box mt={3} sx={{ display: 'flex', justifyContent: 'right' }}>
                    <Button
                      variant={BUTTON_VARIANT.GRADIENT}
                      color={BUTTON_COLOR.DARK}
                      type="submit"
                      disabled={isLoading}
                      onClick={() => handleGenerate(false, true)}>
                      {imageLoading ? (
                        <CircularProgress size={12} color="inherit" />
                      ) : (
                        <Trans>Kép újragenerálása</Trans>
                      )}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Box mb={4} display="flex" justifyContent="center">
            <Typography variant="h5">
              <Trans>Nem található Facebook poszt ehhez a munkához</Trans>
            </Typography>
          </Box>
        </Grid>
      )}

      {fbPosts?.length > 0 ? (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Button onClick={onCancel} disabled={isLoading}>
              <Trans>generic.back</Trans>
            </Button>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.DARK}
              type="submit"
              disabled={isLoading}
              onClick={() => handleSubmit()}>
              <Trans>jobPage.createRoleForm.submitButtonLabel</Trans>
            </Button>
          </Box>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Button onClick={onCancel}>
              <Trans>generic.back</Trans>
            </Button>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.DARK}
              type="submit"
              onClick={() => handleGenerate(true, true)}>
              {isLoading ? (
                <CircularProgress size={12} color="inherit" />
              ) : (
                <Trans>Poszt generálása</Trans>
              )}
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogActions, DialogContent, Fade } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Trans, useTranslation } from 'react-i18next';

import { Typography } from 'src/components/Typography';
import { Box } from 'src/components/Box';
import { useStoreState, useStoreActions } from 'src/stores';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { NewJobData } from 'src/api/hooks/useJob.types';
import { useGetInvitedWorkerRequest } from 'src/api/hooks/useWorker';
import { InviteWorkersForm } from 'src/pages/Workers/components/InviteWorkersForm';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { DataTable } from 'src/components/DataTable';
import { Button } from 'src/components/Button';
import { handleOpenWorkerProfile } from 'src/utils/workerUtils';
import { useWorkerColumnsCheckbox } from 'src/hooks/useWorkerColumns';

export interface InviteWorkersToJobProps {
  handleNextButtonClick: (newJobData: NewJobData) => void;
  handlePreviousButtonClick: () => void;
  selectedWorkers: WorkerProfile[];
  setSelectedWorkers: (workers: WorkerProfile[]) => void;
  newJobData: NewJobData;
}

export const InviteWorkersToJob: React.FC<InviteWorkersToJobProps> = ({
  handleNextButtonClick,
  handlePreviousButtonClick,
  selectedWorkers,
  setSelectedWorkers,
  newJobData,
}) => {
  const { t } = useTranslation();

  const [isInviteFormOpen, setIsInviteFormOpen] = useState<boolean>(false);
  const [recruitmentNeeded, setRecruitmentNeeded] = useState<boolean>(
    newJobData?.recruitmentNeeded || true,
  );
  const [workersCount, setWorkersCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPageCount, setTotalPages] = useState<number>(1);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);

  const workers = useStoreState(state => state.workers.invitedWorkers);
  const setInvitedWorkers = useStoreActions(action => action.workers.setInvitedWorkers);

  const handleRecruitmentChange = (
    _event: React.MouseEvent<HTMLElement>,
    isRecruitmentNeeded: boolean,
  ) => {
    setRecruitmentNeeded(isRecruitmentNeeded);
  };

  const [getInvitedWorkers, { loading: isLoading }] = useGetInvitedWorkerRequest({
    onComplete: res => {
      if (res) {
        setInvitedWorkers(res.finalizedWorkers);
        setTotalPages(res.totalPageCount);
        setWorkersCount(res.workersCount);
      }
    },
    onError: res => {
      console.log('failed', res);
    },
  });

  useEffect(() => {
    getInvitedWorkers({ variables: { currentPage } });
  }, [currentPage]);

  const handleToggle = (worker: WorkerProfile) => {
    const existingWorker = selectedWorkers.find(selectedWorker => selectedWorker.id === worker.id);

    if (existingWorker) {
      setSelectedWorkers(
        selectedWorkers.filter(selectedWorker => selectedWorker.id !== existingWorker.id),
      );
    } else {
      setSelectedWorkers([...selectedWorkers, worker]);
    }
  };

  const onSubmit = () => {
    const submitData = {
      ...newJobData,
      recruitmentNeeded,
    };

    handleNextButtonClick(submitData);
  };

  const columns = useWorkerColumnsCheckbox({
    t,
    handleOpenWorkerProfile,
    selectedWorkers,
    handleToggle,
  });

  const handleClose = () => {
    setIsInviteFormOpen(false);
    setIsInviteModalOpen(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box mt={2} mb={2} display="flex" justifyContent="center">
            <ToggleButtonGroup
              color="info"
              value={recruitmentNeeded}
              exclusive
              onChange={handleRecruitmentChange}>
              <ToggleButton value={true}>
                <Trans>jobPage.createJobModal.recruitmentNeededLabel</Trans>
              </ToggleButton>
              <ToggleButton value={false}>
                <Trans>jobPage.createJobModal.hiddenPositionLabel</Trans>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box mb={4} display="flex" justifyContent="center">
            <Typography
              variant="body2"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              <Trans>
                {recruitmentNeeded
                  ? 'jobPage.createJobModal.recruitmentNeeded'
                  : 'jobPage.createJobModal.hiddenPosition'}
              </Trans>
            </Typography>
          </Box>
          <Box mb={2} mt={2}>
            <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
              <Trans>jobPage.createJobForm.inviteTitle</Trans>
            </Typography>
            <Typography
              variant="body2"
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              color={TYPOGRAPHY_COLORS.TEXT}>
              <Trans>jobPage.createJobModal.helperText3</Trans>
            </Typography>
          </Box>
        </Grid>
        <Typography variant="h6">
          <Trans>jobPage.createJobForm.selectedWorkers</Trans> {selectedWorkers.length}
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" mt={4}>
          <Box display="flex" flexDirection="column">
            <Button
              onClick={() => setIsInviteModalOpen(true)}
              variant={BUTTON_VARIANT.CONTAINED}
              size={BUTTON_SIZE.MEDIUM}
              color={BUTTON_COLOR.INFO}>
              <Trans>jobPage.createJobModal.inviteWorkersButton</Trans>
            </Button>
          </Box>
        </Box>
        {isInviteModalOpen && (
          <Dialog
            TransitionComponent={Fade}
            transitionDuration={300}
            open={isInviteModalOpen}
            onClose={handleClose}
            fullWidth
            keepMounted
            maxWidth={isInviteFormOpen ? 'sm' : 'xxl'}
            PaperProps={{
              sx: {
                width: '100%',
                padding: '10px',
              },
            }}>
            <DialogContent>
              <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                {isInviteFormOpen ? (
                  <InviteWorkersForm
                    onCancel={() => setIsInviteFormOpen(false)}
                    onSuccess={handleToggle}
                  />
                ) : (
                  workers && (
                    <DataTable
                      canSearch
                      isLoading={isLoading}
                      selectedWorkers={selectedWorkers}
                      table={{
                        columns,
                        rows: workers,
                      }}
                      changePage={newPage => setCurrentPage(newPage)}
                      workersCount={workersCount}
                      totalPageCount={totalPageCount}
                      currentPage={currentPage}
                      title={t('jobPage.inviteWorkersModal.title')}
                    />
                  )
                )}
              </Box>
            </DialogContent>

            {!isInviteFormOpen && (
              <Box>
                <DialogActions sx={{ width: '100%' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button
                        variant={BUTTON_VARIANT.GRADIENT}
                        color={BUTTON_COLOR.DARK}
                        type="submit"
                        onClick={() => setSelectedWorkers([])}
                        disabled={!!isLoading || selectedWorkers.length === 0}>
                        <Trans>jobPage.inviteWorkersModal.resetSelectionButton</Trans>
                      </Button>
                      <Button
                        variant={BUTTON_VARIANT.GRADIENT}
                        color={BUTTON_COLOR.DARK}
                        type="submit"
                        onClick={() => setIsInviteFormOpen(true)}
                        disabled={!!isLoading}>
                        <Trans>jobPage.inviteWorkersModal.addNewStudentButton</Trans>
                      </Button>
                    </Box>
                    <Button
                      variant={BUTTON_VARIANT.GRADIENT}
                      color={BUTTON_COLOR.DARK}
                      type="submit"
                      onClick={() => setIsInviteModalOpen(false)}
                      disabled={!!isLoading}>
                      <Trans>jobPage.inviteWorkersModal.submitButton</Trans>
                    </Button>
                  </Box>
                </DialogActions>
              </Box>
            )}
          </Dialog>
        )}

        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mt={4}>
            <Box mr={3}>
              <Button onClick={handlePreviousButtonClick}>
                <Trans>generic.previous</Trans>
              </Button>
            </Box>
            <Box ml={3}>
              <Button
                variant={BUTTON_VARIANT.GRADIENT}
                color={BUTTON_COLOR.DARK}
                type="submit"
                onClick={onSubmit}>
                <Trans>jobPage.createRoleForm.submitButtonLabel</Trans>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { POOL_STATUS } from 'src/DTO/PoolStatus.enum';
import { getNewestByCreatedDate } from 'src/utils/getNewestByCreatedDate';

export const getJobTitles = (worker: WorkerProfile) => {
  if (!worker.jobs || worker.jobs.length === 0) return undefined;
  if (worker.jobs.length > 1) {
    return `${getNewestByCreatedDate(worker.jobs).role.name} +${worker.jobs.length - 1} more`;
  }
  return worker.jobs[0].role.name;
};

export const getOfferTitles = (worker: WorkerProfile) => {
  if (!worker.offers || worker.offers.length === 0) return undefined;
  if (worker.offers.length > 1 || (worker.jobs && worker.jobs.length > 1)) {
    return `${getNewestByCreatedDate(worker.offers).job.role.name} +${
      worker.offers.concat((worker.jobs as any) || []).length - 1
    } more`;
  }
  return getNewestByCreatedDate(worker.offers).job.role.name;
};

export const getApplicationTitles = (worker: WorkerProfile) => {
  if (!worker.applications || worker.applications.length === 0) return undefined;
  return getNewestByCreatedDate(worker.applications).job.role.name;
};

export const getJobsTitle = (worker: WorkerProfile) => {
  const { poolStatus } = worker;
  if (poolStatus === POOL_STATUS.ACTIVE) {
    return getJobTitles(worker);
  }
  if (poolStatus === POOL_STATUS.PENDING_OFFER || poolStatus === POOL_STATUS.WORKER_UNDER_REVIW) {
    return getOfferTitles(worker);
  }
  if (poolStatus === POOL_STATUS.NEW_APPLICATION) {
    return getApplicationTitles(worker);
  }
  return undefined;
};

export const handleOpenWorkerProfile = (worker: WorkerProfile) => {
  const workerUrl = `/worker/${worker.id}`;
  window.open(workerUrl, '_blank');
};

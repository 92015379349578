import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';

import { Button } from 'src/components/Button';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import colors from 'src/theme/base/colors';
import { ScheduleFacebookPostStep2Props } from './types';
import boxShadows from 'src/theme/base/boxShadows';
import { PostFrequency, PostType } from 'src/DTO/FacebookPost.type';
import { calculateDateDetails, getPostScheduleDescription } from 'src/utils/facebookPostUtils';

export const ScheduleFacebookPostStep2: React.FC<ScheduleFacebookPostStep2Props> = ({
  onSubmit,
  handlePreviousButtonClick,
  postSchedule,
  handleMoveToNextStep,
}) => {
  const { currentDate, calculatedEndDate } = calculateDateDetails(postSchedule?.startDate);

  const handleFrequencyChange = (event: SelectChangeEvent<PostFrequency>) => {
    const frequency = event.target.value as PostFrequency;
    onSubmit({ ...postSchedule, frequency });
  };

  const handlePostType = (event: SelectChangeEvent<PostType>) => {
    const postType = event.target.value as PostType;
    onSubmit({ ...postSchedule, postType });
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const startTime = event.target.value || '';
    onSubmit({ ...postSchedule, startTime });
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const startDate = event.target.value || '';
    onSubmit({ ...postSchedule, startDate, endDate: '' });
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const endDate = event.target.value || '';
    onSubmit({ ...postSchedule, endDate });
  };

  const scheduleDescription = useMemo(
    () => getPostScheduleDescription(postSchedule),
    [postSchedule],
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box mb={4} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h5">
              <Trans>Válaszd ki az ütemezés beállításait</Trans>
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            border={`2px solid ${colors.inputColors.borderColor.main}`}
            borderRadius={3}
            boxShadow={boxShadows.sm}
            sx={{
              bgcolor: colors.grey[100],
              p: 2,
            }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
                  Gyakoriság:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  sx={{
                    '.MuiInputBase-root': {
                      padding: '0 !important',
                      height: '35px !important',
                    },
                  }}>
                  <Select
                    IconComponent={ArrowDownwardIcon}
                    value={postSchedule?.frequency}
                    onChange={handleFrequencyChange}>
                    {Object.values(PostFrequency).map(value => (
                      <MenuItem
                        key={value}
                        value={value}
                        sx={{
                          '.MuiInputBase-root': {
                            padding: '0 !important',
                            height: '35px !important',
                          },
                        }}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
                  Kezdési dátum:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="date"
                  inputProps={{
                    min: currentDate,
                  }}
                  value={postSchedule?.startDate}
                  onChange={handleStartDateChange}
                  size="small"
                  aria-label="Start date"
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
                  Kezdési idő:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="time"
                  value={postSchedule?.startTime}
                  onChange={handleTimeChange}
                  size="small"
                  aria-label="Start time"
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
                  Lejárati dátum:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="date"
                  inputProps={{
                    min: postSchedule?.startDate,
                    max: calculatedEndDate,
                  }}
                  value={postSchedule?.endDate || ''}
                  onChange={handleEndDateChange}
                  size="small"
                  aria-label="End date"
                  fullWidth
                  disabled={!postSchedule?.startDate}
                />
              </Grid>

              <Grid item xs={6} mb={4}>
                <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
                  Posztolás módja:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  sx={{
                    '.MuiInputBase-root': {
                      padding: '0 !important',
                      height: '35px !important',
                    },
                  }}>
                  <Select
                    IconComponent={ArrowDownwardIcon}
                    value={postSchedule?.postType || PostType.POST}
                    onChange={handlePostType}>
                    {Object.values(PostType).map(value => (
                      <MenuItem
                        key={value}
                        value={value}
                        sx={{
                          '.MuiInputBase-root': {
                            padding: '0 !important',
                            height: '35px !important',
                          },
                        }}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
                  Ütemezett posztolás:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
                  {scheduleDescription}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Button onClick={handlePreviousButtonClick}>
              <Trans>generic.back</Trans>
            </Button>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.DARK}
              type="submit"
              onClick={handleMoveToNextStep}
              disabled={
                !postSchedule.startDate ||
                !postSchedule.frequency ||
                !postSchedule.startTime ||
                !postSchedule.endDate
              }>
              <Trans>jobPage.createRoleForm.submitButtonLabel</Trans>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Typography } from 'src/components/Typography';
import { DashboardLayout } from 'src/components/layouts/LayoutContainers/DashboardLayout';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { CreateJobDialog } from 'src/pages/Job/components/CreateJobDialog';

export const EmptyFacebookView: React.FC = () => {
  const [isCreateJobDialogOpen, setIsCreateJobDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsCreateJobDialogOpen(true);
  };

  return (
    <DashboardLayout>
      <Box mb={1}>
        <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
          <Trans>
            Jelenleg nem rendelkezel állásokkal, amelyekhez Facebook posztokat generálhatnál,
            ütemezhetnél
          </Trans>
        </Typography>
        <Typography
          variant="button"
          color={TYPOGRAPHY_COLORS.TEXT}
          fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
          <Trans>Kérlek hozz létre egy állást!</Trans>
        </Typography>
      </Box>
      <Box mb={2}>
        <Button
          variant={BUTTON_VARIANT.GRADIENT}
          size={BUTTON_SIZE.SMALL}
          color={BUTTON_COLOR.INFO}
          onClick={handleOpenDialog}>
          <Trans>jobPage.createJob</Trans>
        </Button>
      </Box>

      <CreateJobDialog open={isCreateJobDialogOpen} setOpen={setIsCreateJobDialogOpen} />
    </DashboardLayout>
  );
};

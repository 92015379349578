import React, { useMemo, useEffect, useState, useCallback } from 'react';

// react-table components
import {
  useTable,
  useFilters,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  HeaderGroup,
  Row,
} from 'react-table';
import { Grid, CircularProgress } from '@mui/material';
import { Trans } from 'react-i18next';

// @mui material components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Icon from '@mui/material/Icon';
import _ from 'lodash';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { Input } from 'src/components/Input';
import { Pagination } from 'src/components/Pagination';

import DataTableHeadCell from './DataTableHeadCell';
import DataTableBodyCell from './DataTableBodyCell';
import DataTableHeadFilterCell from './DataTableHeadFilterCell';
import { DataTableProps } from './types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from '../Typography/types';
import theme from 'src/theme';

function DataTable({
  entriesPerPage = { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch = false,
  showTotalEntries = true,
  table,
  pagination = { variant: 'gradient', color: 'info' },
  isSorted = true,
  noEndBorder = false,
  title,
  changePage,
  totalPageCount,
  workersCount,
  currentPage,
  selectedWorkers,
  isLoading,
}: DataTableProps) {
  const defaultValue = entriesPerPage?.defaultValue ? entriesPerPage.defaultValue : 10;
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const canNextPage = currentPage && totalPageCount && currentPage < totalPageCount;
  const canPreviousPage = currentPage !== 1;

  const pageOptions: number[] = _.range(1, totalPageCount && totalPageCount + 1);
  const customizedPageOptions = pageOptions.map((option: number) => option);

  const [paginationInputValue, setPaginationInputValue] = useState<number | string>(1);

  const defaultColumn: Record<string, string> = React.useMemo(
    () => ({
      Filter: '',
    }),
    [],
  );

  const tableInstance = useTable(
    { columns, data, defaultColumn },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageSize, globalFilter },
  } = tableInstance as any;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  const goNextPage = () => {
    nextPage();
    if (changePage && currentPage) changePage(currentPage + 1);
  };

  const goPrevPage = () => {
    previousPage();
    if (changePage && currentPage) changePage(currentPage - 1);
  };

  const goPage = (option: number) => {
    gotoPage(option);
    if (changePage) changePage(option);
  };

  const debouncedGoPage = useCallback(
    _.debounce((pageNumber: number) => {
      gotoPage(pageNumber);
      changePage?.(pageNumber);
    }, 1250),
    [gotoPage, changePage],
  );

  useEffect(() => {
    debouncedGoPage;

    return () => {
      debouncedGoPage.cancel();
    };
  }, [debouncedGoPage]);

  const handlePaginationChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setPaginationInputValue(value);

    if (value === '' || isNaN(Number(value))) {
      return;
    }

    const pageNumber = Number(value);
    const correctPageNumber = Math.min(Math.max(1, pageNumber), customizedPageOptions.length);

    if (pageNumber !== correctPageNumber) {
      setPaginationInputValue(String(correctPageNumber));
    }

    debouncedGoPage(correctPageNumber);
  };
  // Render the paginations
  const renderPagination = pageOptions.map((option: number) => (
    <Pagination
      item
      key={option}
      onClick={() => goPage(Number(option))}
      active={currentPage === option}>
      {option}
    </Pagination>
  ));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column: any) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce';
    } else if (isSorted) {
      sortedValue = 'none';
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart =
    currentPage && (currentPage === 1 ? currentPage : pageSize * (currentPage - 1) + 1);
  // Setting the entries ending point
  let entriesEnd;

  if (currentPage) {
    if (currentPage === 1) {
      entriesEnd = pageSize;
    } else if (currentPage === pageOptions.length) {
      entriesEnd = workersCount;
    } else {
      entriesEnd = pageSize * currentPage;
    }
  }

  useEffect(() => {
    if (currentPage) {
      setPaginationInputValue(currentPage);
    }
  }, [currentPage]);

  return (
    <TableContainer
      sx={{
        width: '100%',
        maxWidth: '100%',
      }}>
      {entriesPerPage || canSearch ? (
        <Box display="flex" justifyContent="space-between" alignItems="center" p={3}>
          {!!title && (
            <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
              {title}
            </Typography>
          )}
          {canSearch && (
            <Box>
              <Input
                placeholder="Search..."
                value={search}
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </Box>
          )}
        </Box>
      ) : null}
      <Table
        sx={{
          width: {
            [theme.breakpoints.down('xxxl')]: {
              width: '95%',
            },
          },
          tableLayout: { xs: 'auto', sm: 'auto', lg: 'fixed', xl: 'fixed' },
        }}
        {...getTableProps()}>
        <Box component="thead">
          {headerGroups.map((headerGroup: HeaderGroup) => (
            <React.Fragment key={headerGroup.id}>
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                key={`headerGroup-${headerGroup.id}`}>
                {headerGroup.headers.map((column: any, i: number) => (
                  <DataTableHeadCell
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    width={column.width ? column.width : 'auto'}
                    align={column.align ? column.align : 'left'}
                    sorted={setSortedValue(column)}
                    key={`${column.id}-${i}`}>
                    {column.render('Header')}
                  </DataTableHeadCell>
                ))}
              </TableRow>
              <TableRow {...headerGroup.getHeaderGroupProps()} key={`filterRow-${headerGroup.id}`}>
                {headerGroup.headers.map((column: any, i: number) => (
                  <DataTableHeadFilterCell key={`${column.id}-${i}`}>
                    {column.canFilter ? column.render('Filter') : column.render('Header')}
                  </DataTableHeadFilterCell>
                ))}
              </TableRow>
            </React.Fragment>
          ))}
        </Box>
        {isLoading ? (
          <Grid item lg={12}>
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <TableBody {...getTableBodyProps()}>
            {page.map((row: Row, index: number) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} key={index}>
                  {row.cells.map((cell: any, i: number) => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === index}
                      align={cell.column.align ? cell.column.align : 'left'}
                      {...cell.getCellProps()}
                      key={i}>
                      {cell.render('Cell')}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>

      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}>
        {showTotalEntries && (
          <Box mb={{ xs: 3, sm: 0 }}>
            <Typography
              variant="button"
              color={TYPOGRAPHY_COLORS.SECONDARY}
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
              <Trans i18nKey="jobPage.inviteWorkersModal.pagination.entriesInfo">
                <Typography>
                  {{ entriesStart }}
                  {{ entriesEnd }}
                  {{ workersCount }}
                </Typography>
              </Trans>
            </Typography>
            {selectedWorkers && (
              <Typography
                variant="button"
                color={TYPOGRAPHY_COLORS.SECONDARY}
                fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                display="block">
                <Trans i18nKey="jobPage.inviteWorkersModal.pagination.selectedWorkersInfo">
                  <Typography>{{ workers: selectedWorkers.length }}</Typography>
                </Trans>
              </Typography>
            )}
            <Typography
              variant="button"
              color={TYPOGRAPHY_COLORS.SECONDARY}
              fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
              display="block">
              <Trans i18nKey="jobPage.inviteWorkersModal.pagination.pagesInfo">
                <Typography>{{ totalPageCount }}</Typography>
              </Trans>
            </Typography>
          </Box>
        )}
        {!isLoading && pageOptions.length > 1 && (
          <Pagination
            variant={pagination.variant ? pagination.variant : 'gradient'}
            color={pagination.color ? pagination.color : 'info'}>
            {canPreviousPage && (
              <Pagination item onClick={goPrevPage}>
                <Icon sx={{ fontWeight: 'bold' }}>chevron_left</Icon>
              </Pagination>
            )}
            {currentPage && renderPagination.length > 6 ? (
              <Box width="5rem" mx={1}>
                <Input
                  type="number"
                  value={paginationInputValue}
                  onChange={handlePaginationChange}
                />
              </Box>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <Pagination item onClick={goNextPage}>
                <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
              </Pagination>
            )}
          </Pagination>
        )}
      </Box>
    </TableContainer>
  );
}

export { DataTable };

import { PostScheduleStatus, PageData, GroupData } from 'src/pages/Facebook/components/types';
import { Job } from './Job.type';

export type PostSchedule = {
  jobId: string;
  name: string;
  city: string;
  startTime: string;
  startDate: string;
  endDate: string;
  frequency: PostFrequency;
  postType: PostType;
  status: PostScheduleStatus;
  pageData: PageData[];
  groupData: GroupData[];
  postText: string;
  postPictureUrl: string;
};

export interface FacebookPost {
  id: string;
  postText?: string;
  postPictureUrl?: string;
  job?: Job;
  status: PostStatus;
}

export enum PostStatus {
  PENDING = 'Pending',
  POSTED = 'Posted',
  REJECTED = 'Rejected',
}

export enum PostFrequency {
  DAILY = 'Daily',
  EVERY_SECOND_DAY = 'Every second day',
  WEEKLY = 'Weekly',
}

export enum PostType {
  SHARE = 'Share',
  POST = 'Post',
}

export enum PageOptions {
  DELLA_DIAKMUNKA = 'Della Diákmunka',
}

export enum GroupOptions {
  DIAKMUNKA_STUDENT_WORK_BUDAPEST = 'Diákmunka / Student Work Budapest',
}

/* eslint-disable indent */
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { PostScheduleStatus } from 'src/pages/Facebook/components/types';
import colors from 'src/theme/base/colors';
import { TYPOGRAPHY_WEIGHT } from '../Typography/types';
import { UpdateFacebookPostSchedule } from 'src/pages/Facebook/components/UpdateFacebookPostSchedule';
import { Footer } from '../Footer';
import boxShadows from 'src/theme/base/boxShadows';
import { TableRowComponent } from './TableRow';
import { TableHeadCell } from './TableHeadCell';
import { PostSchedule } from 'src/DTO/FacebookPost.type';
import { ScheduledFacebookPostsProps } from './types';

export const ScheduledFacebookPosts: React.FC<ScheduledFacebookPostsProps> = ({
  setSchedulePosts,
  scheduledPosts,
  updateScheduledPost,
  handleRestartSchedule,
  getScheduledPostsLoading,
}) => {
  const { lg } = boxShadows;

  const [isScheduleUpdateDialogOpen, setIsScheduleUpdateDialogOpen] = useState<boolean>(false);

  const [selectedPostSchedule, setSelectedPostSchedule] = useState<PostSchedule>();

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof PostSchedule>('name');

  const handleRequestSort = (property: keyof PostSchedule) => {
    setOrder(prevOrder => (orderBy === property && prevOrder === 'asc' ? 'desc' : 'asc'));
    setOrderBy(property);
  };

  const isEditable = (post: PostSchedule) =>
    post.status === PostScheduleStatus.INACTIVE && new Date(post.startDate) > new Date();

  const sortData = (array: PostSchedule[], orderBy: keyof PostSchedule, order: 'asc' | 'desc') => {
    if (!array) return [];
    return [...array].sort((a, b) => {
      let aValue = a[orderBy] ?? '';
      let bValue = b[orderBy] ?? '';

      if (orderBy === 'name' && a?.name && b.name) {
        aValue = a.name;
        bValue = b.name;
      } else if (orderBy === 'city' && a.city && b.city) {
        aValue = a.city;
        bValue = b.city;
      }

      if (aValue < bValue) return order === 'asc' ? -1 : 1;
      if (aValue > bValue) return order === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const getStatus = (post: PostSchedule) => {
    return post?.status === PostScheduleStatus.ACTIVE
      ? PostScheduleStatus.INACTIVE
      : PostScheduleStatus.ACTIVE;
  };

  const handleChange = (FBPost: PostSchedule) => {
    setSchedulePosts(prevDetails => {
      return prevDetails?.map(post =>
        post?.jobId === FBPost?.jobId
          ? {
              ...post,
              status: getStatus(post),
            }
          : post,
      );
    });

    if (FBPost?.jobId) {
      updateScheduledPost({
        variables: {
          scheduledPostData: {
            ...FBPost,
            jobId: FBPost.jobId,
            status: getStatus(FBPost),
          },
        },
      });
    }
  };

  const handleOpenEditor = (post: PostSchedule) => {
    setSelectedPostSchedule(post);
    setIsScheduleUpdateDialogOpen(true);
  };

  if (getScheduledPostsLoading) {
    return (
      <Grid item xs={12}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center">
      {scheduledPosts && scheduledPosts?.length > 0 ? (
        <Grid item xs={12} lg={11} sx={{ marginBottom: '5%' }}>
          <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM} mb={3}>
            <Trans>Ütemezett posztok</Trans>
          </Typography>

          <Box
            mb={5}
            sx={{
              paddingBottom: 3,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <TableContainer
              component={Paper}
              sx={{
                border: `0.5px solid ${colors.inputColors.borderColor.main}`,
                borderRadius: 3,
                padding: 2,
                boxShadow: lg,
              }}>
              <Table size="small" aria-label="compact table">
                <TableHead sx={{ display: 'table-header-group' }}>
                  <TableRow>
                    <TableHeadCell
                      label="Munka neve"
                      columnKey="name"
                      orderBy={orderBy}
                      order={order}
                      handleRequestSort={() => handleRequestSort('name')}
                    />
                    <TableHeadCell
                      label="Város"
                      columnKey="city"
                      orderBy={orderBy}
                      order={order}
                      handleRequestSort={() => handleRequestSort('city')}
                    />
                    <TableHeadCell
                      label="Státusz"
                      columnKey="status"
                      orderBy={orderBy}
                      order={order}
                      handleRequestSort={() => handleRequestSort('status')}
                    />
                    <TableHeadCell
                      label="Gyakoriság"
                      columnKey="frequency"
                      orderBy={orderBy}
                      order={order}
                      handleRequestSort={() => handleRequestSort('frequency')}
                    />
                    <TableHeadCell
                      label="Kezdési dátum"
                      columnKey="startDate"
                      orderBy={orderBy}
                      order={order}
                      handleRequestSort={() => handleRequestSort('startDate')}
                    />
                    <TableHeadCell
                      label="Kezdési idő"
                      columnKey="startTime"
                      orderBy={orderBy}
                      order={order}
                      handleRequestSort={() => handleRequestSort('startTime')}
                    />
                    <TableHeadCell
                      label="Lejárati dátum"
                      columnKey="endDate"
                      orderBy={orderBy}
                      order={order}
                      handleRequestSort={() => handleRequestSort('endDate')}
                    />
                    <TableCell>
                      <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                        Posztok száma
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                        Utolsó poszt
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                        Aktiválás
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                        Műveletek
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortData(scheduledPosts, orderBy, order).map((row, index) => (
                    <TableRowComponent
                      key={row?.jobId}
                      row={row}
                      index={index}
                      handleChange={() => handleChange(row)}
                      handleOpenEditor={() => handleOpenEditor(row)}
                      isEditable={isEditable}
                      handleRestartSchedule={() => handleRestartSchedule(row?.jobId)}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Footer />
        </Grid>
      ) : null}

      {selectedPostSchedule && scheduledPosts && (
        <UpdateFacebookPostSchedule
          setPostDetails={setSchedulePosts}
          selectedPostSchedule={selectedPostSchedule}
          isScheduleUpdateDialogOpen={isScheduleUpdateDialogOpen}
          setIsScheduleUpdateDialogOpen={setIsScheduleUpdateDialogOpen}
          updateScheduledPost={updateScheduledPost}
        />
      )}
    </Grid>
  );
};

import { Dispatch, SetStateAction } from 'react';
import { BaseRequest } from 'src/api/hooks/useApi.types';
import {
  GetScheduledFacebookPostsPayload,
  ScheduleFacebookPostPayload,
  UpdateScheduledFacebookPostPayload,
} from 'src/api/hooks/useFacebookPost.types';
import { GenerateFacebookPostPayload } from 'src/api/hooks/useJob.types';
import { FacebookPost, PostSchedule } from 'src/DTO/FacebookPost.type';
import { Job } from 'src/DTO/Job.type';

export enum PostScheduleStatus {
  INACTIVE = 'Inactive',
  ACTIVE = 'Active',
}

export interface ScheduleFacebookPostStep3Props {
  postSchedule: PostSchedule;
  onSubmit: (updatedDetails: PostSchedule) => void;
  handlePreviousButtonClick: () => void;
  handleMoveToNextStep: () => void;
}

export interface ScheduleFacebookPostStep2Props {
  selectedJob: Job;
  onSubmit: (updatedDetails: PostSchedule) => void;
  handlePreviousButtonClick: () => void;
  handleMoveToNextStep: () => void;
  postSchedule: PostSchedule;
  fbPosts: FacebookPost[];
}

export interface ScheduleFacebookPostStep1Props {
  imageLoading: boolean;
  textLoading: boolean;
  getFbPostLoading: boolean;
  generatedText: string;
  fbPosts: FacebookPost[];
  selectedJob: Job;
  onCancel: () => void;
  handleMoveToNextStep: () => void;
  setImageLoading: Dispatch<SetStateAction<boolean>>;
  setTextLoading: Dispatch<SetStateAction<boolean>>;
  generateFbPost: BaseRequest<GenerateFacebookPostPayload>;
  setFbPosts: Dispatch<SetStateAction<FacebookPost[]>>;
}

export interface ConfirmFacebookPostScheduleProps {
  handleClose: () => void;
  handlePreviousButtonClick: () => void;
  handleMoveToNextStep: () => void;
  postSchedule: PostSchedule;
  fbPosts: FacebookPost[];
  setSchedulePosts: Dispatch<SetStateAction<PostSchedule[]>>;
  scheduleFacebookPost: BaseRequest<ScheduleFacebookPostPayload>;
}

export interface UpdateFacebookPostScheduleProps {
  isScheduleUpdateDialogOpen: boolean;
  setPostDetails: Dispatch<SetStateAction<PostSchedule[]>>;
  selectedPostSchedule: Readonly<PostSchedule>;
  setIsScheduleUpdateDialogOpen: Dispatch<SetStateAction<boolean>>;
  updateScheduledPost: BaseRequest<UpdateScheduledFacebookPostPayload>;
}

export interface CreateFacebookPostScheduleProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  selectedJob: Job;
  setSchedulePosts: Dispatch<SetStateAction<PostSchedule[]>>;
  scheduleFacebookPost: BaseRequest<ScheduleFacebookPostPayload>;
  getScheduledPosts: BaseRequest<GetScheduledFacebookPostsPayload>;
}

export interface ThankYouPageProps {
  handleClose: () => void;
}

export interface PageData {
  name: string;
  url: string;
}

export type GroupData = PageData;

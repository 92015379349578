import { TableRow, TableCell, Typography, Stack, Switch } from '@mui/material';
import { PostScheduleStatus } from 'src/pages/Facebook/components/types';
import { BUTTON_VARIANT, BUTTON_COLOR } from '../Button/types';
import { TYPOGRAPHY_WEIGHT } from '../Typography/types';
import React from 'react';
import { Button } from '../Button';
import { TableRowProps } from './types';
import { isPostScheduleExpired } from 'src/utils/facebookPostUtils';

export const TableRowComponent: React.FC<TableRowProps> = ({
  row,
  index,
  handleChange,
  handleOpenEditor,
  isEditable,
  handleRestartSchedule,
}) => {
  if (!row) return null;

  const isExpired = isPostScheduleExpired(row);

  return (
    <TableRow
      key={index}
      sx={{
        backgroundColor: isExpired ? '#f5b1b1' : 'inherit',
      }}>
      <TableCell>
        <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
          {row.name}
        </Typography>
      </TableCell>
      <TableCell>{row.city}</TableCell>
      <TableCell>{row.status}</TableCell>
      <TableCell>{row.frequency}</TableCell>
      <TableCell>{row.startDate}</TableCell>
      <TableCell>{row.startTime}</TableCell>
      <TableCell
        sx={{
          color: isExpired ? 'white' : 'inherit',
          backgroundColor: isExpired ? '#f5b1b1' : 'inherit',
          textDecoration: isExpired ? 'line-through' : 'none',
          fontWeight: isExpired ? 'bold' : 'normal',
        }}>
        {row.endDate}
      </TableCell>
      {/* Placeholder values */}
      <TableCell>1</TableCell>
      <TableCell>2025-01-12</TableCell>
      <TableCell>
        {!isExpired && (
          <Stack direction="row" spacing={1}>
            <Switch
              checked={row.status === PostScheduleStatus.ACTIVE}
              onChange={() => handleChange(row)}
            />
          </Stack>
        )}
      </TableCell>
      <TableCell>
        <Button
          variant={BUTTON_VARIANT.GRADIENT}
          color={BUTTON_COLOR.DARK}
          disabled={!isExpired && !isEditable(row)}
          onClick={() => (isExpired ? handleRestartSchedule(row.jobId) : handleOpenEditor(row))}>
          {isExpired ? 'Újraindítás' : 'Szerkesztés'}
        </Button>
      </TableCell>
    </TableRow>
  );
};

import { TableCell, TableSortLabel, Typography } from '@mui/material';
import { TYPOGRAPHY_WEIGHT } from '../Typography/types';
import React from 'react';
import { TableHeadCellProps } from './types';

export const TableHeadCell: React.FC<TableHeadCellProps> = ({
  label,
  columnKey,
  orderBy,
  order,
  handleRequestSort,
}) => {
  return (
    <TableCell>
      <TableSortLabel
        active={orderBy === columnKey}
        direction={orderBy === columnKey ? order : 'asc'}
        onClick={() => handleRequestSort(columnKey)}>
        <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
          {label}
        </Typography>
      </TableSortLabel>
    </TableCell>
  );
};

import * as Yup from 'yup';
import { TFunction } from 'i18next';

const JOB_TITLE_MIN_LENGTH = 3;
const JOB_DESCRIPTION_MIN_LENGTH = 15;

export const creatJobSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string()
      .test('name', function (value, ctx) {
        const { nameEn } = ctx.parent;
        if (!value && !nameEn) {
          return ctx.createError({ message: t('validation.required') });
        }
        return true;
      })
      .min(JOB_TITLE_MIN_LENGTH, t('validation.minLength', { number: JOB_TITLE_MIN_LENGTH }))
      .nullable(),
    nameEn: Yup.string()
      .test('nameEn', function (value, ctx) {
        const { name } = ctx.parent;
        if (!value && !name) {
          return ctx.createError({ message: t('validation.required') });
        }
        return true;
      })
      .min(JOB_TITLE_MIN_LENGTH, t('validation.minLength', { number: JOB_TITLE_MIN_LENGTH }))
      .nullable(),
    description: Yup.string().min(
      JOB_DESCRIPTION_MIN_LENGTH,
      t('validation.minLength', { number: JOB_DESCRIPTION_MIN_LENGTH }),
    ),
    descriptionEn: Yup.string().min(
      JOB_DESCRIPTION_MIN_LENGTH,
      t('validation.minLength', { number: JOB_DESCRIPTION_MIN_LENGTH }),
    ),
    companyProfile: Yup.string().min(
      JOB_DESCRIPTION_MIN_LENGTH,
      t('validation.minLength', { number: JOB_DESCRIPTION_MIN_LENGTH }),
    ),
    companyProfileEn: Yup.string().min(
      JOB_DESCRIPTION_MIN_LENGTH,
      t('validation.minLength', { number: JOB_DESCRIPTION_MIN_LENGTH }),
    ),
    county: Yup.string().required(t('validation.required')).nullable(),
    postCode: Yup.string()
      .min(4, t('validation.minimum4Char'))
      .matches(/^[0-9]+$/, t('validation.onlyNumbers')),
    city: Yup.string().min(3, t('validation.minimum3Char')),
    address: Yup.string().min(3, t('validation.minimum3Char')),
  });

/* eslint-disable indent */
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';

import { useGetJobs } from 'src/api/hooks/useJob';
import { Job } from 'src/DTO/Job.type';
import Settings from 'src/assets/icons/Settings';
import { useFacebookPosts } from 'src/hooks/useFacebookPosts';
import { Trans } from 'react-i18next';
import { useStoreActions, useStoreState } from 'src/stores';
import { JOB_STATUS } from 'src/DTO/JobStatus.enum';
import { EmptyFacebookView } from 'src/components/EmptyFacebookView/EmptyFacebookView';
import { ScheduledFacebookPosts } from 'src/components/ScheduledFacebookPost/ScheduledFacebookPosts';
import { CustomSlider, sliderSettings } from 'src/components/Slider/Slider';
import { TYPOGRAPHY_WEIGHT, TYPOGRAPHY_TRANSFORM } from 'src/components/Typography/types';
import { CreateFacebookPostSchedule } from './components/CreateFacebookPostSchedule';
import { Footer } from 'src/components/Footer';
import { Input } from 'src/components/Input';
import { JobProperties } from './types';

export const FacebookAI: React.FC = () => {
  const {
    getScheduledPosts,
    scheduledPosts,
    setScheduledPosts,
    scheduleFacebookPost,
    updateScheduledPost,
    getScheduledPostsLoading,
  } = useFacebookPosts();

  const jobs = useStoreState(state => state.jobs.jobs);
  const setJobs = useStoreActions(actions => actions.jobs.setJobs);

  const activeJobs = useMemo(
    () => jobs.filter(job => job.status !== JOB_STATUS.TERMINATED),
    [jobs],
  );

  const [isFacebookDialogOpen, setIsFacebookDialogOpen] = useState<boolean>(false);
  const [jobProperties, setJobProperties] = useState<JobProperties>({
    selectedJob: {} as Job,
    jobs: [] as Job[],
    search: '',
  });

  const allJobs = useMemo(() => {
    return jobProperties?.jobs?.filter(job =>
      job?.role?.name?.toLowerCase().includes(jobProperties?.search?.toLowerCase().trim()),
    );
  }, [jobProperties]);

  const [getJobs, { loading: isGettingJobsLoading }] = useGetJobs({
    onComplete: res => {
      if (res) {
        setJobProperties({
          ...jobProperties,
          jobs: res,
        });
        setJobs(res);
      }
    },
  });

  const handleSearch = (event: FormEvent<HTMLInputElement>) => {
    setJobProperties({
      ...jobProperties,
      search: event?.currentTarget?.value,
    });
  };

  const handleJobSelection = (job: Job) => {
    setJobProperties({
      ...jobProperties,
      selectedJob: job,
    });
    setIsFacebookDialogOpen(true);
  };

  useEffect(() => {
    if (jobProperties?.jobs !== activeJobs) {
      setJobProperties(prevState => ({
        ...prevState,
        jobs: activeJobs,
      }));
    }
  }, [activeJobs, jobProperties.jobs]);

  useEffect(() => {
    getJobs();
    getScheduledPosts();
  }, []);

  if (isGettingJobsLoading) {
    return (
      <Grid item xs={12}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  const handleRestartSchedule = (jobId: string) => {
    const jobToRestart = jobProperties?.jobs.find(job => job.id === jobId);

    if (!jobToRestart) return;

    handleJobSelection(jobToRestart);
  };

  const isJobListEmpty = jobs && jobs.length === 0;

  return (
    <Box>
      {isJobListEmpty && <EmptyFacebookView />}
      {!isJobListEmpty && (
        <Box pb={2}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={11} sx={{ marginBottom: '5%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'right',
                }}>
                <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                  <Trans>Hozd létre az automata Facebook posztokat</Trans>
                </Typography>
                <Box display="flex" justifyContent="right" alignItems="right" maxWidth="30%">
                  <Input
                    placeholder="Search..."
                    value={jobProperties?.search}
                    onChange={e => handleSearch(e)}
                  />
                </Box>
              </Box>

              <CustomSlider {...sliderSettings}>
                {allJobs?.map((job, index) => (
                  <Card
                    key={index}
                    sx={{
                      maxHeight: '100%',
                      height: '100px',
                      width: '150px',
                      maxWidth: '80%',
                      '&:hover': {
                        transform: 'scale(1.02, 1.02)',
                      },
                    }}>
                    <CardContent>
                      <CardActionArea
                        onClick={() => handleJobSelection(job)}
                        sx={{
                          height: '200px',
                          padding: 0,
                          '&[data-active]': {
                            backgroundColor: 'blue',
                            '&:hover': {
                              backgroundColor: 'red',
                            },
                          },
                          '.MuiCardActionArea-focusHighlight': {
                            background: 'transparent',
                          },
                        }}>
                        <CardContent sx={{ height: '100%' }}>
                          <Box p={1}>
                            <Box display="flex" alignItems="center">
                              <Settings size="35px" />
                              <Box ml={3} lineHeight={0}>
                                <Box mb={1} lineHeight={0}>
                                  <Typography
                                    variant="h6"
                                    textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}
                                    fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                                    {job?.role?.name}
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary">
                                    {job?.role?.city}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </CardContent>
                  </Card>
                ))}
              </CustomSlider>
              {scheduledPosts?.length === 0 && <Footer />}
            </Grid>

            <ScheduledFacebookPosts
              setSchedulePosts={setScheduledPosts}
              scheduledPosts={scheduledPosts}
              updateScheduledPost={updateScheduledPost}
              handleRestartSchedule={handleRestartSchedule}
              getScheduledPostsLoading={getScheduledPostsLoading}
            />

            {jobProperties?.selectedJob && (
              <CreateFacebookPostSchedule
                getScheduledPosts={getScheduledPosts}
                open={isFacebookDialogOpen}
                setOpen={setIsFacebookDialogOpen}
                selectedJob={jobProperties.selectedJob}
                setSchedulePosts={setScheduledPosts}
                scheduleFacebookPost={scheduleFacebookPost}
              />
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

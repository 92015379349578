import React, { useMemo } from 'react';
import { Box, Checkbox } from '@mui/material';
import { Row } from 'react-table';

import { DropDownFilterProps, ColumnType } from 'src/components/ColumnFilter/types';
import { POOL_STATUS } from 'src/DTO/PoolStatus.enum';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { SearchFilter, DropDownFilter } from 'src/components/ColumnFilter';
import { WorkerActionsCell } from 'src/pages/Workers/components/WorkerActionsCell';
import { WorkerStatusCell } from 'src/pages/Workers/components/WorkerStatusCell';
import { WorkerNameCell } from 'src/pages/Workers/components/WorkerNameCell';
import { getWorkerNameWithAge } from 'src/utils/getWorkerNameWithAge';
import { getJobsTitle } from 'src/utils/workerUtils';
import { WorkerColumnCheckboxProps, WorkerColumnProps } from './useWorkerColumns.types';

export const useWorkerColumns = ({ t, handleOpenWorkerProfile }: WorkerColumnProps) => {
  return useMemo(
    () => [
      {
        Header: t('workersScreen.worker'),
        accessor: 'name',
        Filter: (props: DropDownFilterProps) => (
          <SearchFilter {...props} columnType={ColumnType.WORKER_NAME} />
        ),
        filter: 'text',
        Cell: ({ row }: { row: Row<WorkerProfile> }) => {
          const worker = row.original;
          const lastImageUrl =
            worker.imageUrls && worker.imageUrls.length > 0
              ? worker.imageUrls[worker.imageUrls.length - 1]
              : undefined;
          return (
            <WorkerNameCell
              name={getWorkerNameWithAge(worker)}
              handleOpenWorkerProfile={() => handleOpenWorkerProfile(worker)}
              image={lastImageUrl}
            />
          );
        },
      },
      {
        Header: t('workersScreen.phone'),
        accessor: 'phoneNumber',
        Filter: (props: DropDownFilterProps) => (
          <SearchFilter {...props} columnType={ColumnType.PHONE_NUMBER} />
        ),
      },
      {
        Header: t('workersScreen.status'),
        accessor: 'poolStatus',
        Filter: (props: DropDownFilterProps) => (
          <DropDownFilter {...props} columnType={ColumnType.STATUS} />
        ),
        Cell: ({ value }: { value: POOL_STATUS }) => <WorkerStatusCell poolStatus={value} />,
      },
      {
        Header: t('workersScreen.job'),
        accessor: (worker: WorkerProfile) => getJobsTitle(worker),
        Filter: (props: DropDownFilterProps) => (
          <DropDownFilter {...props} columnType={ColumnType.JOB} />
        ),
      },
      {
        Header: t('workersScreen.actions'),
        accessor: (worker: WorkerProfile) => <WorkerActionsCell worker={worker} />,
        Filter: (props: DropDownFilterProps) => (
          <DropDownFilter {...props} columnType={ColumnType.ACTION} />
        ),
      },
    ],
    [t, handleOpenWorkerProfile],
  );
};

export const useWorkerColumnsCheckbox = ({
  t,
  handleOpenWorkerProfile,
  selectedWorkers,
  handleToggle,
}: WorkerColumnCheckboxProps) => {
  const workerColumns = useWorkerColumns({ t, handleOpenWorkerProfile });

  return useMemo(
    () => [
      {
        Header: t('workersScreen.worker'),
        accessor: 'name',
        Filter: (props: DropDownFilterProps) => (
          <SearchFilter {...props} columnType={ColumnType.WORKER_NAME} />
        ),
        filter: 'text',
        Cell: ({ row }: { row: Row<WorkerProfile> }) => {
          const worker = row.original;
          const isSelected = selectedWorkers.some(w => w.id === worker.id);
          const lastImageUrl = worker.imageUrls?.[worker.imageUrls.length - 1];

          return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Checkbox
                checked={isSelected}
                onChange={() => handleToggle(worker)}
                sx={{
                  color: 'primary.main',
                  '&.Mui-checked': { color: 'primary.main' },
                  marginRight: '15px',
                }}
              />
              <WorkerNameCell
                name={getWorkerNameWithAge(worker)}
                handleOpenWorkerProfile={() => handleOpenWorkerProfile(worker)}
                image={lastImageUrl}
              />
            </Box>
          );
        },
      },
      ...workerColumns.slice(1),
    ],
    [t, selectedWorkers, handleToggle, workerColumns],
  );
};

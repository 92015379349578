import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useGetScheduledFacebookPosts,
  useScheduleFacebookPost,
  useUpdatePostSchedule,
  useUpdateFacebookPost,
} from 'src/api/hooks/useFacebookPost';
import { useGenerateFacebookPost } from 'src/api/hooks/useJob';
import { PostSchedule } from 'src/DTO/FacebookPost.type';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';

export const useFacebookPosts = () => {
  const { t } = useTranslation();

  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const [scheduledPosts, setScheduledPosts] = useState<PostSchedule[]>([]);

  const [generatedText, setGeneratedText] = useState<string>('');
  const [generatedImage, setGeneratedImage] = useState<string>('');

  const [textGenerationLoading, setTextLoading] = useState<boolean>(false);
  const [imageGenerationLoading, setImageLoading] = useState<boolean>(false);

  const handleSuccess = (message: string) => {
    setPopUpData({
      isOpen: true,
      type: PopUpEnum.success,
      message: t(message),
    });
  };

  const handleFailure = (message: string) => {
    setPopUpData({
      isOpen: true,
      type: PopUpEnum.error,
      message: t(message),
    });
  };

  const [generateFbPost] = useGenerateFacebookPost({
    onComplete: res => {
      if (res) {
        if (res.texts) {
          setTextLoading(true);
          setGeneratedText(res.texts);
        }
        if (res.images) {
          setImageLoading(true);
          setGeneratedImage(res.images);
        }
        handleSuccess('facebookPost.scheduling.alerts.generatePost.success');
        setTextLoading(false);
        setImageLoading(false);
      }
    },
    onError: () => {
      handleFailure('facebookPost.scheduling.alerts.generatePost.error');
    },
  });

  const [getScheduledPosts, { loading: getScheduledPostsLoading }] = useGetScheduledFacebookPosts({
    onComplete: res => {
      if (res?.jobsWithSchedules) {
        const mappedSchedules = res.jobsWithSchedules.map(job => {
          const schedule = job.postSchedule;

          const { jobId, name, city, ...scheduleDetails } = schedule;

          return {
            jobId: job?.id,
            name: job?.role?.name || '',
            city: job?.role?.city || '',
            ...scheduleDetails,
          };
        });

        setScheduledPosts(mappedSchedules);
      }
    },
    onError: () => {
      handleFailure('facebookPost.scheduling.alerts.getScheduledPosts.error');
    },
  });

  const [scheduleFacebookPost] = useScheduleFacebookPost({
    onComplete: () => {
      handleSuccess('facebookPost.scheduling.alerts.schedulePost.success');
    },
    onError: () => {
      handleFailure('facebookPost.scheduling.alerts.schedulePost.error');
    },
  });

  const [updateScheduledPost] = useUpdatePostSchedule({
    onComplete: () => {
      handleSuccess('facebookPost.scheduling.alerts.updateScheduledPost.success');
    },
    onError: () => {
      handleFailure('facebookPost.scheduling.alerts.updateScheduledPost.error');
    },
  });

  const [updateFacebookPost] = useUpdateFacebookPost({
    onComplete: () => {
      handleSuccess('facebookPost.scheduling.alerts.updatePost.success');
    },
    onError: () => {
      handleFailure('facebookPost.scheduling.alerts.updatePost.success');
    },
  });

  return {
    generatedText,
    generatedImage,
    scheduledPosts,
    textGenerationLoading,
    imageGenerationLoading,
    getScheduledPostsLoading,
    generateFbPost,
    setTextLoading,
    setImageLoading,
    scheduleFacebookPost,
    updateFacebookPost,
    getScheduledPosts,
    setScheduledPosts,
    updateScheduledPost,
    setGeneratedText,
  };
};

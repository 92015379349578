import React, { useContext } from 'react';
import { Grid, Card, CardContent } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Avatar } from 'src/components/Avatar';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Typography } from 'src/components/Typography';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { NewJobData } from 'src/api/hooks/useJob.types';
import { FinancialDetails } from 'src/components/FinancialDetails';
import { CreateRoleForm } from './CreateRoleForm.types';
import { JobWage } from 'src/DTO/Job.type';
import { DrivingLicenseTypes, GenderTypes, JobTypes } from '../Job.types';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { LangContext } from 'src/context/LanguageContext';
import { useLocalizedData } from 'src/utils/useLocalizeData';

export interface ConfirmJobDetailsProps {
  handleSubmit: () => void;
  handlePreviousButtonClick: () => void;
  role?: CreateRoleForm;
  selectedWorkers: WorkerProfile[];
  newJobData?: NewJobData;
  inviteWorkersToJobLoading: boolean;
  isEditMode?: boolean;
  wage?: JobWage;
}

export const ConfirmJobDetails: React.FC<ConfirmJobDetailsProps> = ({
  handleSubmit,
  handlePreviousButtonClick,
  role,
  selectedWorkers,
  newJobData,
  inviteWorkersToJobLoading,
  isEditMode,
  wage,
}) => {
  const { t } = useTranslation();
  const { selectedLanguages } = useContext(LangContext);
  const { localizedJob } = useLocalizedData(newJobData, isEditMode);

  const shouldShowMaleLabel =
    newJobData?.genderTypes?.length === 1 && newJobData?.genderTypes?.includes(GenderTypes.Male);

  const shouldShowFemaleLabel =
    newJobData?.genderTypes?.length === 1 && newJobData?.genderTypes?.includes(GenderTypes.Female);
  const { en = false, hu = false } = selectedLanguages || {};

  const huLang: boolean = isEditMode
    ? localizedJob?.selectedLanguages?.hu || localizedJob === undefined
    : !!hu;

  const enLang: boolean = isEditMode ? !!localizedJob?.selectedLanguages?.en : !!en;

  const county = role?.county
    ? `${role?.county} ${t('jobPage.createRoleForm.county').toLowerCase()}`
    : '';
  const location = [county, role?.postCode, role?.city, role?.address].filter(Boolean).join(', ');
  const feor = [role?.feor?.code, role?.feor?.text].filter(Boolean).join('. ');
  const roleNameAndHours = [role?.name, newJobData?.weeklyHours].filter(Boolean).join(', ');
  const roleNameAndHoursEn = [role?.nameEn, newJobData?.weeklyHours].filter(Boolean).join(', ');

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2} mt={2} display="flex" justifyContent="center">
          <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
            <Trans>jobPage.createJobForm.confirmTitle</Trans>
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={6} style={{ display: 'flex' }}>
            <Card style={{ flex: 1, display: 'flex' }}>
              <CardContent style={{ flex: 1 }}>
                <Box mt={2} mb={2}>
                  {huLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobModal.jobTitle</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {`${roleNameAndHours} ${t('jobPage.hoursPerMonth')}` ||
                          t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  {enLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobModal.jobTitleEn</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {(role?.nameEn && `${roleNameAndHoursEn} ${t('jobPage.hoursPerMonth')}`) ||
                          t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  {huLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createRoleForm.companyProfile</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {role?.companyProfile || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  {enLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createRoleForm.companyProfileEn</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {role?.companyProfileEn || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  <Typography variant="subtitle2">
                    <Trans>generic.location</Trans>
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {location || t('jobPage.createJobModal.notProvided')}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="subtitle2">FEOR</Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {feor || t('jobPage.createJobModal.notProvided')}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex' }}>
            <Card style={{ flex: 1, display: 'flex' }}>
              <CardContent style={{ flex: 1 }}>
                <Box mt={2} mb={2}>
                  {huLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobModal.jobDescription</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {role?.description || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  {enLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobModal.jobDescriptionEn</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {role?.descriptionEn || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Box mt={4} mb={1}>
          <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
            <Trans>jobPage.financialDetails</Trans>
          </Typography>
        </Box>

        <Grid container>
          {wage && newJobData && (
            <FinancialDetails
              hourlyGrossWage={wage.hourlyGrossAmount}
              monthlyHours={newJobData.monthlyHours}
            />
          )}
        </Grid>

        <Box mt={4} mb={2}>
          <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
            <Trans>jobPage.createJobModal.data</Trans>
          </Typography>
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={6} style={{ display: 'flex' }}>
            <Card style={{ flex: 1, display: 'flex' }}>
              <CardContent style={{ flex: 1 }}>
                <Box mb={2}>
                  <Typography variant="subtitle2">
                    <Trans>jobPage.createJobModal.jobType</Trans>
                  </Typography>
                  {newJobData?.jobType === JobTypes.LongtermJob && (
                    <Typography
                      variant="body2"
                      fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                      color={TYPOGRAPHY_COLORS.INFO}>
                      <Trans>jobPage.createJobForm.longTermJob</Trans>
                    </Typography>
                  )}
                  {newJobData?.jobType === JobTypes.ShortTermJob && (
                    <Typography
                      variant="body2"
                      fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                      color={TYPOGRAPHY_COLORS.INFO}>
                      <Trans>jobPage.createJobForm.shortTermJob</Trans>
                    </Typography>
                  )}
                </Box>
                <Box mb={2}>
                  {huLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobModal.schedule</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {newJobData?.timeSchedule || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  {enLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobModal.scheduleEn</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {localizedJob?.timeSchedule || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>

                <Box mb={2}>
                  <Typography variant="subtitle2">
                    <Trans>jobPage.createJobModal.workerCount</Trans>
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {newJobData?.workerCount || t('jobPage.createJobModal.notProvided')}
                  </Typography>
                </Box>
                <Box mb={2}>
                  {newJobData?.genderTypes?.length === 2 && (
                    <Typography
                      variant="body2"
                      fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                      color={TYPOGRAPHY_COLORS.INFO}>
                      <Trans>jobPage.createJobForm.girlsAndBoys</Trans>
                    </Typography>
                  )}
                  {shouldShowMaleLabel && (
                    <Typography
                      variant="body2"
                      fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                      color={TYPOGRAPHY_COLORS.INFO}>
                      <Trans>jobPage.createJobForm.boys</Trans>
                    </Typography>
                  )}
                  {shouldShowFemaleLabel && (
                    <Typography
                      variant="body2"
                      fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                      color={TYPOGRAPHY_COLORS.INFO}>
                      <Trans>jobPage.createJobForm.girls</Trans>
                    </Typography>
                  )}
                </Box>

                <Box mb={2}>
                  <Typography variant="subtitle2">
                    <Trans>jobPage.createJobForm.startNow</Trans>
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {newJobData?.startNow ? <Trans>generic.yes</Trans> : <Trans>generic.no</Trans>}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="subtitle2">
                    <Trans>jobPage.createJobForm.language</Trans>
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {newJobData?.hungarianSkillsNeeded || newJobData?.englishSkillsNeeded ? (
                      <>
                        {newJobData?.hungarianSkillsNeeded && (
                          <>
                            <Trans>jobPage.createJobModal.hungarian</Trans>
                            {newJobData?.englishSkillsNeeded && <>, </>}
                          </>
                        )}
                        {newJobData?.englishSkillsNeeded && (
                          <>
                            <Trans>jobPage.createJobModal.english</Trans>
                          </>
                        )}
                      </>
                    ) : (
                      t('jobPage.createJobModal.notProvided')
                    )}
                  </Typography>
                </Box>
                <Box mb={2}>
                  {huLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobForm.documents</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {newJobData?.documents || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  {enLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobForm.documentsEn</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {localizedJob?.documents || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  {huLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobForm.extraSkills</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {newJobData?.extraSkills || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  {enLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobForm.extraSkillsEn</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {localizedJob?.extraSkills || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} style={{ display: 'flex' }}>
            <Card style={{ flex: 1, display: 'flex' }}>
              <CardContent style={{ flex: 1 }}>
                <Box mb={2}>
                  <Typography variant="subtitle2">
                    <Trans>jobPage.createJobForm.drivingLicense</Trans>
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {newJobData?.drivingLicense === DrivingLicenseTypes.Car && (
                      <Trans>jobPage.createJobForm.car</Trans>
                    )}
                    {newJobData?.drivingLicense === DrivingLicenseTypes.MotorCycle && (
                      <Trans>jobPage.createJobForm.motorCycle</Trans>
                    )}
                    {newJobData?.drivingLicense === DrivingLicenseTypes.None && (
                      <Trans>jobPage.createJobForm.noLicense</Trans>
                    )}
                  </Typography>
                </Box>
                <Box mb={2}>
                  {huLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobForm.bonus</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {newJobData?.bonus || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  {enLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobForm.bonusEn</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {localizedJob?.bonus || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  <Typography variant="subtitle2">
                    <Trans>jobPage.createJobForm.contactPerson</Trans>
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {newJobData?.contactName || t('jobPage.createJobModal.notProvided')}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="subtitle2">
                    <Trans>jobPage.createJobForm.contactPhone</Trans>
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {newJobData?.contactPhone || t('jobPage.createJobModal.notProvided')}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography variant="subtitle2">
                    <Trans>jobPage.createJobForm.contactEmail</Trans>
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {newJobData?.contactEmail || t('jobPage.createJobModal.notProvided')}
                  </Typography>
                </Box>
                <Box mb={2}>
                  {huLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobForm.arriveInfo</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {newJobData?.arriveInfo || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  {enLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobForm.arriveInfoEn</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {localizedJob?.arriveInfo || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  {huLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobForm.equipmentInfo</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {newJobData?.equipmentInfo || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  {enLang && (
                    <>
                      <Typography variant="subtitle2">
                        <Trans>jobPage.createJobForm.equipmentInfoEn</Trans>
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                        color={TYPOGRAPHY_COLORS.INFO}>
                        {localizedJob?.equipmentInfo || t('jobPage.createJobModal.notProvided')}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box mb={2}>
                  <Typography variant="subtitle2">
                    <Trans>jobPage.createJobModal.recruitmentType</Trans>
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}
                    color={TYPOGRAPHY_COLORS.INFO}>
                    {newJobData?.recruitmentNeeded ? (
                      <Trans>generic.yes</Trans>
                    ) : (
                      <Trans>generic.no</Trans>
                    )}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Box mt={4} mb={1}>
          <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
            <Trans>jobPage.workers</Trans>
          </Typography>
        </Box>

        <Card sx={{ overflow: 'scroll' }}>
          <Box width="100%" pt={3} pb={2.5} px={3}>
            <Grid container wrap="nowrap">
              {selectedWorkers.map(worker => (
                <Grid
                  key={worker.name}
                  item
                  xs={4}
                  sm={3}
                  md={2}
                  mr={1}
                  lg={1}
                  sx={{ flex: '0 0 100%' }}>
                  <Box
                    borderRadius="50%"
                    width="3.625rem"
                    height="3.625rem"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    color="white"
                    mx="auto"
                    border="1px solid grey"
                    sx={{ cursor: 'pointer' }}>
                    <Avatar src={worker.imageUrls[worker.imageUrls.length - 1]} alt={worker.name} />
                  </Box>
                  <Box mt={0.75} textAlign="center" lineHeight={1}>
                    <Typography variant="caption">{worker.name}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={4}>
          <Box mr={3}>
            <Button onClick={handlePreviousButtonClick} disabled={inviteWorkersToJobLoading}>
              <Trans>generic.previous</Trans>
            </Button>
          </Box>
          <Box ml={3}>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.DARK}
              type="submit"
              onClick={handleSubmit}
              disabled={inviteWorkersToJobLoading}>
              {isEditMode && <Trans>jobPage.createJobForm.editButtonLabel</Trans>}
              {!isEditMode && <Trans>jobPage.createJobForm.buttonLabel</Trans>}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

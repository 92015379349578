import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Card,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/Button';

import { CreateJob } from './CreateJobForm';
import { CreateRole } from './CreateRoleForm';
import { InviteWorkersToJob } from './InviteWorkersToJob';
import { NewJobData } from 'src/api/hooks/useJob.types';
import { ConfirmJobDetails } from './ConfirmJobDetails';
import { WorkerProfile } from 'src/DTO/WorkerProfile.type';
import { useCreateJob, useCreateJobOfferBatch } from 'src/api/hooks/useJob';
import { useStoreActions } from 'src/stores';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { CreateRoleForm } from './CreateRoleForm.types';
import { useCreateRole } from 'src/api/hooks/useRole';
import { Role } from 'src/DTO/Role.type';
import { Job, JobWage } from 'src/DTO/Job.type';
import { CreateJobStep1 } from './CreateJobStep1';
import { CreateJobStep2 } from './CreateJobStep2';
import { CreateJobStep4 } from './CreateJobStep4';
import { CreateJobStep5 } from './CreateJobStep5';
import { EditModes } from '../Job.types';

import { TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';

const DEFAULT_SELECTED_WORKERS: [] = [];

export interface CreateJobDialogProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  editRoleData?: Role;
  editJobData?: Job;
  editMode?: EditModes;
  onSuccessCallback?: (job: Job) => void;
}

export const CreateJobDialog: React.FC<CreateJobDialogProps> = ({
  open,
  setOpen,
  editMode,
  editRoleData,
  editJobData,
  onSuccessCallback,
}) => {
  const DEFAULT_NEW_ROLE_DATA = editRoleData;
  const DEFAULT_NEW_JOB_DATA = editJobData ?? ({} as NewJobData);

  const { t } = useTranslation();

  /* LOCAL STATE */
  const [activeStep, setActiveStep] = useState(0);
  const [newJobData, setNewJobData] = useState<NewJobData>(Object.assign({}, DEFAULT_NEW_JOB_DATA));
  const [newRoleData, setNewRoleData] = useState<CreateRoleForm | Role | undefined>(
    Object.assign({}, DEFAULT_NEW_ROLE_DATA),
  );
  const [selectedWorkers, setSelectedWorkers] = useState<WorkerProfile[]>(DEFAULT_SELECTED_WORKERS);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const [wageInfo, setWageInfo] = useState<JobWage>();

  /* GLOBAL STATE */
  const setJob = useStoreActions(actions => actions.jobs.setJob);
  const setRole = useStoreActions(actions => actions.jobs.setRole);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);

  const CREATE_JOB_STEPS = [1, 2, 3, 4, 5, 6, 7, 8];

  const handleSuccessMessage = () => {
    if (!!editMode) {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.success,
        message: t('jobPage.createJobForm.editSuccess'),
      });

      return;
    }

    setPopUpData({
      isOpen: true,
      type: PopUpEnum.success,
      message: `${t('jobPage.createJobForm.success')}. ${
        selectedWorkers.length > 0 ? t('jobPage.createJobForm.inviteAfterActivation') : ''
      }`,
    });
  };

  const handleErrorMessage = () => {
    if (!!editMode) {
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('jobPage.createJobForm.editError'),
      });

      return;
    }

    setPopUpData({
      isOpen: true,
      type: PopUpEnum.error,
      message: t('jobPage.createJobForm.error'),
    });
  };

  const reset = () => {
    setActiveStep(0);
    setNewJobData(DEFAULT_NEW_JOB_DATA);
    setNewRoleData(DEFAULT_NEW_ROLE_DATA);
    setSelectedWorkers(DEFAULT_SELECTED_WORKERS);
  };

  const handleShowCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleClose = () => {
    reset();
    setIsConfirmDialogOpen(false);
    setOpen(false);
  };

  const [createRole] = useCreateRole({
    onComplete: newRole => {
      if (newRole) {
        setNewRoleData(newRole);
        setRole(newRole);
        handleCreateJob(newRole);
      }
    },
    onError: res => {
      console.log('failed', res);
    },
  });

  const [createJobOfferBatch, { loading: inviteWorkersToJobLoading }] = useCreateJobOfferBatch({
    onComplete: res => {
      if (res) {
        handleSuccessMessage();
        handleClose();
      }
    },
    onError: () => {
      handleErrorMessage();
      handleClose();
    },
  });

  const [createJob] = useCreateJob({
    onComplete: createdJob => {
      if (createdJob) {
        setJob(createdJob);

        if (selectedWorkers.length > 0) {
          createJobOfferBatch({
            variables: {
              offers: selectedWorkers.map(worker => ({
                workerId: worker.id,
                jobId: createdJob.id,
                name: worker.name,
                phoneNumber: worker.phoneNumber,
              })),
            },
          });
        } else {
          handleSuccessMessage();
          handleClose();
        }

        !!onSuccessCallback && onSuccessCallback(createdJob);
      }
    },
    onError: res => {
      handleErrorMessage();
      console.log('Job Creation failed', res);
    },
  });

  const handleNavigateFromRoleStep = (roleData: CreateRoleForm) => {
    setNewRoleData(roleData);
    handleMoveToNextStep();
  };

  const handleNavigateFromJobStep = (jobData: NewJobData, wage: JobWage) => {
    setNewJobData(jobData);
    setWageInfo(wage);
    handleMoveToNextStep();
  };

  const handleNavigateFromJobStep1 = (jobData: NewJobData) => {
    setNewJobData(jobData);
    handleMoveToNextStep();
  };

  const handleMoveToNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handleMoveToPreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleCreateJob = (role: CreateRoleForm) => {
    if (!newJobData || !role.id) {
      return;
    }

    /* eslint-disable indent */
    const dataToSave = !!editMode
      ? {
          id: editJobData?.id,
          roleId: role.id,
          ...newJobData,
          genderTypes: newJobData.genderTypes?.join(','),
        }
      : {
          roleId: role.id,
          ...newJobData,
          startDate: new Date().toISOString(),
          genderTypes: newJobData.genderTypes?.join(','),
        };
    /* eslint-enable indent */

    createJob({
      variables: dataToSave,
    });
  };

  const handleCreateRole = () => {
    if (!newRoleData) {
      return;
    }

    createRole({
      variables: {
        name: newRoleData.name,
        description: newRoleData.description,
        location: newRoleData.location,
        feor: newRoleData.feor,
        companyProfile: newRoleData.companyProfile,
        postCode: newRoleData.postCode,
        city: newRoleData.city,
        address: newRoleData.address,
        localizedData: newRoleData.localizedData,
        county: newRoleData.county,
      },
    });
  };

  const handleStartCreateJobProcess = () => {
    if (!newRoleData?.id) {
      handleCreateRole();
    } else {
      createRole({ variables: { ...newRoleData } });
    }
  };

  function getStepContent() {
    switch (activeStep) {
      case 0:
        return (
          <CreateRole
            onCancel={handleShowCloseConfirmDialog}
            onSubmit={handleNavigateFromRoleStep}
            roleId={newRoleData?.id}
            newRoleData={Object.keys(newRoleData || {}).length > 0 ? newRoleData : undefined}
            editMode={editMode}
            newJobData={newJobData}
          />
        );
      case 1:
        if (newJobData) {
          return (
            <CreateJobStep1
              handleNextButtonClick={handleNavigateFromJobStep1}
              handlePreviousButtonClick={handleMoveToPreviousStep}
              newJobData={newJobData}
              editMode={editMode}
            />
          );
        }
        return null;
      case 2:
        if (newJobData) {
          return (
            <CreateJobStep2
              handleNextButtonClick={handleNavigateFromJobStep1}
              handlePreviousButtonClick={handleMoveToPreviousStep}
              newJobData={newJobData}
              editMode={editMode}
            />
          );
        }
        return null;
      case 3:
        return (
          <CreateJob
            handleNextButtonClick={handleNavigateFromJobStep}
            handlePreviousButtonClick={handleMoveToPreviousStep}
            newJobData={newJobData}
            editMode={editMode}
          />
        );
      case 4:
        if (newJobData) {
          return (
            <CreateJobStep4
              handleNextButtonClick={handleNavigateFromJobStep1}
              handlePreviousButtonClick={handleMoveToPreviousStep}
              newJobData={newJobData}
            />
          );
        }
        return null;
      case 5:
        if (newJobData) {
          return (
            <CreateJobStep5
              handleNextButtonClick={handleNavigateFromJobStep1}
              handlePreviousButtonClick={handleMoveToPreviousStep}
              newJobData={newJobData}
              editMode={editMode}
            />
          );
        }
        return null;
      case 6:
        if (newJobData) {
          return (
            <InviteWorkersToJob
              handleNextButtonClick={handleNavigateFromJobStep1}
              handlePreviousButtonClick={handleMoveToPreviousStep}
              selectedWorkers={selectedWorkers}
              setSelectedWorkers={setSelectedWorkers}
              newJobData={newJobData}
            />
          );
        }

        return null;
      case 7:
        return (
          <ConfirmJobDetails
            handleSubmit={handleStartCreateJobProcess}
            handlePreviousButtonClick={handleMoveToPreviousStep}
            role={newRoleData}
            selectedWorkers={selectedWorkers}
            newJobData={newJobData}
            inviteWorkersToJobLoading={inviteWorkersToJobLoading}
            isEditMode={!!editMode}
            wage={wageInfo}
          />
        );
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleShowCloseConfirmDialog}
        aria-labelledby="form-dialog-title"
        fullScreen>
        <DialogContent>
          <Box display="flex" justifyContent="flex-end" onClick={handleShowCloseConfirmDialog}>
            <Close style={{ cursor: 'pointer' }} />
          </Box>
          <Box pt={3} pb={8}>
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={8}>
                <Box mt={6} mb={1} textAlign="center">
                  <Box mb={1}>
                    <Typography variant="h3" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
                      {!!editMode && <Trans>jobPage.editJob</Trans>}
                      {!editMode && <Trans>jobPage.createJobTitle</Trans>}
                    </Typography>
                  </Box>
                </Box>

                <Stepper activeStep={activeStep} alternativeLabel>
                  {CREATE_JOB_STEPS.map(label => {
                    return (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <Card>
                  <Box p={4}>
                    <Box>{getStepContent()}</Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={isConfirmDialogOpen} onClose={() => setIsConfirmDialogOpen(false)}>
        <DialogTitle>
          <Trans>jobPage.createJobForm.confirmCloseTitle</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="textPrimary">
            <Trans>jobPage.createJobForm.confirmCLoseText</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmDialogOpen(false)}>
            <Trans>generic.no</Trans>
          </Button>
          <Button onClick={handleClose} variant={BUTTON_VARIANT.GRADIENT} color={BUTTON_COLOR.DARK}>
            <Trans>generic.yes</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

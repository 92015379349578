/* eslint-disable indent */
import { Box, Grid, List, ListItem, TextField, Typography } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import { Avatar } from 'src/components/Avatar';
import { AVATAR_SHADOW, AVATAR_SIZE, AVATAR_VARIANT } from 'src/components/Avatar/types';
import { Button } from 'src/components/Button';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import colors from 'src/theme/base/colors';
import { ConfirmFacebookPostScheduleProps, PostScheduleStatus } from './types';
import boxShadows from 'src/theme/base/boxShadows';

export const ConfirmFacebookPostSchedule: React.FC<ConfirmFacebookPostScheduleProps> = ({
  handlePreviousButtonClick,
  postSchedule,
  handleMoveToNextStep,
  fbPosts,
  setSchedulePosts,
  scheduleFacebookPost,
}) => {
  const {
    frequency,
    startTime,
    startDate,
    endDate,
    name,
    city,
    postType,
    jobId,
    pageData,
    groupData,
    postText,
    postPictureUrl,
  } = postSchedule;

  const details = {
    postText,
    postPictureUrl,
    frequency,
    startDate,
    endDate,
    startTime,
    jobId,
    postType,
    status: PostScheduleStatus.INACTIVE,
    pageData,
    groupData,
  };

  const saveDetails = {
    ...details,
    name: name ?? '',
    city: city ?? '',
  };

  const handleSubmit = () => {
    if (jobId) {
      scheduleFacebookPost({
        variables: {
          postSchedule: {
            ...details,
            jobId,
          },
        },
      });

      setSchedulePosts(prevPosts => [...prevPosts, saveDetails]);
    }

    handleMoveToNextStep();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box mb={4} display="flex" justifyContent="center">
          <Typography variant="h5">
            <Trans>Facebook Posztolás - Összegzés</Trans>
          </Typography>
        </Box>
        {fbPosts.map((fbPost, index) => (
          <Grid item xs={12} key={fbPost.id}>
            <Box
              width="100%"
              border={`2px solid ${colors.inputColors.borderColor.main}`}
              borderRadius={3}
              boxShadow={boxShadows.sm}
              mb={3}
              sx={{
                bgcolor: colors.grey[100],
                p: 2,
              }}>
              <Box display="flex" alignItems="center" gap={1} mb={1}>
                <Box
                  border={`2px solid ${colors.inputColors.borderColor.main}`}
                  borderRadius={3}
                  sx={{
                    width: 24,
                    height: 24,
                    backgroundColor: 'white !important',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '0.7rem',
                    fontWeight: 'bold',
                  }}>
                  {index + 1}.
                </Box>
              </Box>

              <Box
                border={`2px solid ${colors.inputColors.borderColor.main}`}
                borderRadius={3}
                sx={{
                  p: 2,
                  fontSize: 13,
                  bgcolor: 'white !important',
                  lineHeight: 1.5,
                }}>
                <TextField
                  fullWidth
                  multiline
                  aria-readonly
                  variant="outlined"
                  inputProps={{
                    style: {
                      minWidth: '100%',
                      maxHeight: '100%',
                      fontSize: 13,
                      lineHeight: 1.5,
                      borderRadius: 0,
                    },
                    readOnly: true,
                  }}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      border: 0,
                      padding: '0 !important',
                      margin: 0,
                    },
                    '.MuiInputBase-root': {
                      backgroundColor: 'white !important',
                      padding: '0 !important',
                      margin: 0,
                    },
                  }}
                  minRows={1}
                  value={fbPost.postText}
                />
              </Box>
              <Box mt={2} sx={{ display: 'flex', justifyContent: 'right' }}>
                <Avatar
                  alt="Generated image"
                  src={fbPost.postPictureUrl}
                  variant={AVATAR_VARIANT.ROUNDED}
                  size={AVATAR_SIZE.XXL}
                  shadow={AVATAR_SHADOW.SM}
                  onClick={() => {
                    window.open(fbPost.postPictureUrl, '_blank', 'noopener noreferrer');
                  }}
                  sx={{
                    cursor: 'pointer',
                    userSelect: 'none',
                    transition: 'transform 0.4s',
                    '&:hover': {
                      transform: 'scale(1.04)',
                    },
                  }}
                />
              </Box>
            </Box>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
            border={`2px solid ${colors.inputColors.borderColor.main}`}
            borderRadius={3}
            boxShadow={boxShadows.sm}
            mb={3}
            sx={{
              bgcolor: colors.grey[100],
              p: 2,
            }}>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              <Trans>Mely oldalakra posztoljunk:</Trans>
            </Typography>
            <Box>
              <List sx={{ padding: 2 }}>
                {Object.values(postSchedule?.pageData).map((value, index) => (
                  <ListItem key={index} style={{ display: 'list-item', listStyleType: 'disc' }}>
                    <Typography variant="body2">{value.name}</Typography>
                  </ListItem>
                ))}
              </List>
            </Box>

            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              <Trans>Mely csoportokra posztoljunk:</Trans>
            </Typography>
            <Box>
              <List sx={{ padding: 2 }}>
                {Object.values(postSchedule?.groupData).map((value, index) => (
                  <ListItem key={index} style={{ display: 'list-item', listStyleType: 'disc' }}>
                    <Typography variant="body2">{value.name}</Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
            border={`2px solid ${colors.inputColors.borderColor.main}`}
            borderRadius={3}
            boxShadow={boxShadows.sm}
            sx={{
              bgcolor: colors.grey[100],
              p: 2,
            }}>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              <Trans>Ütemezési beállítások:</Trans>
            </Typography>
            <Box>
              <Typography variant="body2">
                <strong>Posztolás módja:</strong> {postSchedule?.postType || 'N/A'}
              </Typography>
              <Typography variant="body2">
                <strong>Kezdési dátum:</strong> {postSchedule?.startDate || 'N/A'}
              </Typography>
              <Typography variant="body2">
                <strong>Kezdési időpont:</strong> {postSchedule?.startTime || 'N/A'}
              </Typography>
              <Typography variant="body2">
                <strong>Gyakoriság:</strong> {postSchedule?.frequency || 'N/A'}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button onClick={handlePreviousButtonClick}>
              <Trans>generic.back</Trans>
            </Button>
            <Button
              onClick={handleSubmit}
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.DARK}
              type="submit">
              <Trans>Mentés</Trans>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';

import { Button } from 'src/components/Button';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import colors from 'src/theme/base/colors';
import boxShadows from 'src/theme/base/boxShadows';
import { ThankYouPageProps } from './types';

export const ThankYouPage: React.FC<ThankYouPageProps> = ({ handleClose }) => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8} md={12}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          p={3}
          border={`2px solid ${colors.inputColors.borderColor.main}`}
          borderRadius={3}
          boxShadow={boxShadows.sm}
          sx={{ bgcolor: colors.grey[100] }}>
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            <Trans>Köszönjük!</Trans>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <Trans>A posztod jóváhagyás alatt van.</Trans>
          </Typography>
          <Typography variant="body2" color="textSecondary" mb={2}>
            <Trans>Szeretnéd, ha jóváhagyás nélkül tudnál posztolni?</Trans>
          </Typography>
          <Button onClick={handleClose} variant={BUTTON_VARIANT.GRADIENT} color={BUTTON_COLOR.DARK}>
            <Trans>Bezárás</Trans>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

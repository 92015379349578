import React from 'react';
import { Grid, FormGroup, Typography } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { useStoreActions } from 'src/stores';
import { Formik, Field, Form } from 'formik';

import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { useInviteWorkerRequest } from 'src/api/hooks/useWorker';
import { FormInputField } from 'src/components/FormInputField/FormInputField';
import { inviteWorkerSchema } from './validationSchemas';
import { InviteWorkerForm, InviteWorkerFormProps } from './types';
import { PopUpEnum } from 'src/stores/NotificationStore';
import { useGetInvitedWorkerRequest } from 'src/api/hooks/useWorker';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';

export const InviteWorkersForm: React.FC<InviteWorkerFormProps> = ({
  onCancel,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation();

  /* GLOBAL STATE */
  const addInvitedWorker = useStoreActions(action => action.workers.addInvitedWorker);
  const setPopUpData = useStoreActions(action => action.popUp.setPopUp);
  const setInvitedWorkers = useStoreActions(action => action.workers.setInvitedWorkers);
  const openConfirmModal = useStoreActions(actions => actions.context.openConfirmDialog);

  const inviteWorkersInit = () => ({
    phoneNumber: '',
    name: '',
  });

  const [getInvitedWorkers] = useGetInvitedWorkerRequest({
    onComplete: res => {
      if (res) {
        setInvitedWorkers(res.finalizedWorkers);
      }
    },
    onError: res => {
      console.log('failed', res);
    },
  });

  const [inviteWorker, { loading }] = useInviteWorkerRequest({
    onComplete: res => {
      if (res) {
        addInvitedWorker(res);
        setPopUpData({
          isOpen: true,
          type: PopUpEnum.success,
          message: t('workersScreen.inviteWorkersForm.success'),
        });

        getInvitedWorkers();

        !!onSuccess && onSuccess(res);
        !!onCancel && onCancel();
      }
    },
    onError: res => {
      console.log('failed', res);
      !!onError && onError();
      setPopUpData({
        isOpen: true,
        type: PopUpEnum.error,
        message: t('workersScreen.inviteWorkersForm.error'),
      });
    },
  });

  const handleSubmit = (data: InviteWorkerForm, resetForm: () => void) => {
    openConfirmModal({
      title: t('workersScreen.inviteWorkersForm.confirmTitle'),
      body: (
        <Box>
          <Trans i18nKey="workersScreen.inviteWorkersForm.confirmText">
            {{ phoneNumber: data.phoneNumber }}
          </Trans>
        </Box>
      ),
      onConfirm: () => {
        inviteWorker({
          variables: {
            ...data,
            phoneNumber: data.phoneNumber.replace(/\s/g, ''),
            shouldNotify: true,
          },
        });
        resetForm();
      },
    });
  };

  return (
    <Grid container>
      <Formik
        initialValues={inviteWorkersInit()}
        validationSchema={inviteWorkerSchema(t)}
        onSubmit={(data, { resetForm }) => handleSubmit(data, resetForm)}>
        {({ isValid, dirty }) => (
          <Form style={{ width: '100%' }}>
            <Grid container>
              <Box sx={{ paddingBottom: 5 }}>
                <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                  <Trans>jobPage.inviteWorkersModal.addNewStudent.title</Trans>
                </Typography>
              </Box>

              <Grid item xs={12}>
                <Field
                  label={t('workersScreen.inviteWorkersForm.name')}
                  name="name"
                  component={FormInputField}
                  variant="outlined"
                />
                <Field
                  label={t('workersScreen.inviteWorkersForm.phoneNumber')}
                  name="phoneNumber"
                  component={FormInputField}
                  variant="outlined"
                  placeholder="+36701111111"
                />
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                    {!!onCancel && (
                      <Box mr={2}>
                        <Button disabled={loading} onClick={onCancel}>
                          <Trans>workersScreen.inviteWorkersForm.cancel</Trans>
                        </Button>
                      </Box>
                    )}
                    <Box ml={2}>
                      <Button
                        variant={BUTTON_VARIANT.GRADIENT}
                        color={BUTTON_COLOR.DARK}
                        type="submit"
                        disabled={!isValid || !dirty || loading}>
                        {loading ? (
                          <Trans>generic.loading</Trans>
                        ) : (
                          <Trans>workersScreen.inviteWorkersForm.invite</Trans>
                        )}
                      </Button>
                    </Box>
                  </Box>
                </FormGroup>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

import { SelectChangeEvent } from '@mui/material';
import { Dispatch } from 'react';
import { PostSchedule, PostFrequency, PageOptions, GroupOptions } from 'src/DTO/FacebookPost.type';
import { PageData, GroupData } from 'src/pages/Facebook/components/types';

interface DateDetails {
  calculatedEndDate: string;
  currentDate: string;
}

interface FormattedDateDetails {
  formattedStartDate: string;
  formattedEndDate: string;
  formattedTime: string;
}

export const formatDateDetails = (
  startDate: string,
  endDate: string,
  time: string,
): FormattedDateDetails => {
  const formattedStartDate = new Date(startDate).toLocaleDateString('hu-HU');
  const formattedEndDate = new Date(endDate).toLocaleDateString('hu-HU');

  const [hours, minutes] = time.split(':');

  const formattedTime = `${hours}:${minutes}`;

  return { formattedStartDate, formattedEndDate, formattedTime };
};

export const getPostScheduleDescription = (postSchedule: PostSchedule): string => {
  const { startDate, startTime, frequency, endDate } = postSchedule;

  const frequencyLabels = {
    [PostFrequency.WEEKLY]: 'Hetente',
    [PostFrequency.DAILY]: 'Naponta',
    [PostFrequency.EVERY_SECOND_DAY]: 'Két naponta',
  };

  const frequencyLabel = frequencyLabels[frequency];

  if (!startDate || !startTime || !frequency || !endDate || !frequencyLabel)
    return 'Nincs ütemezve';

  const { formattedStartDate, formattedEndDate, formattedTime } = formatDateDetails(
    startDate,
    endDate,
    startTime,
  );

  return `${frequencyLabel}: ${formattedStartDate}, ${formattedTime} - ${formattedEndDate}, ${formattedTime}`;
};

const getURL = (inputURL: string, page: string): string => {
  if (page === PageOptions.DELLA_DIAKMUNKA) {
    return 'https://www.facebook.com/people/Teszt/61566857665285/';
  } else if (page === GroupOptions.DIAKMUNKA_STUDENT_WORK_BUDAPEST) {
    return 'https://www.facebook.com/groups/1139651699543550/';
  }
  return inputURL;
};

export const setPageGroupData = (
  event: SelectChangeEvent<string[]>,
  setData: Dispatch<React.SetStateAction<string[]>>,
  Data: PageData[] | GroupData[],
  setFinalData:
    | Dispatch<React.SetStateAction<PageData[]>>
    | Dispatch<React.SetStateAction<GroupData[]>>,
  inputURL: string,
) => {
  const updatedData = event.target.value;

  const dataArray = Array.isArray(updatedData) ? updatedData : [updatedData];

  setData(dataArray);

  const newData = dataArray.filter(dataArr => !Data.some(data => data?.name === dataArr));

  return setFinalData([
    ...Data,
    ...newData.map(data => ({
      name: data,
      url: getURL(inputURL, data),
    })),
  ]);
};

export const isPostScheduleExpired = (post: PostSchedule): boolean => {
  if (!post || !post?.endDate || !post?.startTime) return false;

  const today = new Date();

  const postEndDate = new Date(post.endDate).toISOString().split('T')[0];
  const postTime = post.startTime;

  const hours = String(today.getHours()).padStart(2, '0');
  const minutes = String(today.getMinutes()).padStart(2, '0');

  const currentDate = today.toISOString().split('T')[0];
  const currentTime = `${hours}:${minutes}`;

  const isPostEndingToday = currentDate === postEndDate;

  return isPostEndingToday ? currentTime >= postTime : currentDate > postEndDate;
};

export const calculateDateDetails = (startDate: string): DateDetails => {
  const newDate = startDate ? new Date(startDate) : new Date();

  const endDate = new Date(newDate);
  endDate.setDate(newDate.getDate() + 30);

  const calculatedEndDate = endDate.toISOString().split('T')[0];
  const currentDate = new Date().toISOString().split('T')[0];

  return { calculatedEndDate, currentDate };
};

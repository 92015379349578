import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material';
import React from 'react';
import Slider, { CustomArrowProps, Settings } from 'react-slick';

const arrowStyles = (hasMoreSlides: boolean) => {
  return { color: 'black', fontSize: '2rem', zIndex: 2, display: hasMoreSlides ? 'block' : 'none' };
};

const customArrowProps = (props: CustomArrowProps) => {
  const { className, onClick } = props;
  const hasMoreSlides = onClick !== null;

  return { className, hasMoreSlides, onClick };
};

const PrevArrow = (props: CustomArrowProps) => {
  const { className, hasMoreSlides, onClick } = customArrowProps(props);

  return (
    <ArrowBackIosIcon
      className={className}
      onClick={onClick}
      style={{ ...arrowStyles(hasMoreSlides), left: -40 }}
    />
  );
};

const NextArrow = (props: CustomArrowProps) => {
  const { className, hasMoreSlides, onClick } = customArrowProps(props);

  return (
    <ArrowForwardIosIcon
      className={className}
      onClick={onClick}
      style={{ ...arrowStyles(hasMoreSlides), right: -40 }}
    />
  );
};

export const sliderSettings: Settings = {
  dots: true,
  infinite: false,
  speed: 750,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const CustomSlider = styled(Slider)`
  .slick-list {
    padding: 30px !important;
  }
`;

import { Close } from '@mui/icons-material';
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import { useFacebookPosts } from 'src/hooks/useFacebookPosts';
import { CreateFacebookPostScheduleProps } from './types';
import { FacebookPost, PostFrequency, PostSchedule, PostType } from 'src/DTO/FacebookPost.type';
import { useGetFacebookPosts } from 'src/api/hooks/useFacebookPost';
import { ConfirmFacebookPostSchedule } from './ConfirmFacebookPostSchedule';
import { ScheduleFacebookPostStep1 } from './ScheduleFacebookPostStep1';
import { ScheduleFacebookPostStep2 } from './ScheduleFacebookPostStep2';
import { ScheduleFacebookPostStep3 } from './ScheduleFacebookPostStep3';
import { ThankYouPage } from './ThankYou';

const STEPS = [1, 2, 3, 4, 5];

export const CreateFacebookPostSchedule: React.FC<CreateFacebookPostScheduleProps> = ({
  open,
  setOpen,
  selectedJob,
  setSchedulePosts,
  scheduleFacebookPost,
  getScheduledPosts,
}) => {
  const {
    generateFbPost,
    setTextLoading,
    setImageLoading,
    textGenerationLoading,
    imageGenerationLoading,
    generatedText,
    generatedImage,
  } = useFacebookPosts();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const [postSchedule, setPostSchedule] = useState<PostSchedule>({} as PostSchedule);
  const [fbPosts, setFbPosts] = useState<FacebookPost[]>([]);

  const [getFacebookPosts, { loading: getFbPostLoading }] = useGetFacebookPosts({
    onComplete: res => {
      if (res?.posts?.length) {
        const firstPost = res.posts[0];

        setPostSchedule({
          ...postSchedule,
          jobId: firstPost?.job?.id ?? '',
          frequency: postSchedule?.frequency ?? PostFrequency.DAILY,
          postType: postSchedule?.postType ?? PostType.POST,
          postText: firstPost?.postText ?? '',
          postPictureUrl: firstPost?.postPictureUrl ?? '',
        });
        setFbPosts(res.posts);
      } else {
        setFbPosts([]);
      }
    },
  });

  const reset = () => {
    setPostSchedule({} as PostSchedule);
    setActiveStep(0);
  };

  const handleShowCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleClose = () => {
    getScheduledPosts();
    setIsConfirmDialogOpen(false);
    setOpen(false);
    reset();
  };

  const handleMoveToNextStep = () => {
    setActiveStep(prevStep => prevStep + 1);
  };

  const handleMoveToPreviousStep = () => {
    setActiveStep(prevStep => prevStep - 1);
  };

  const saveChanges = (updatedScheduleData: PostSchedule) => {
    setPostSchedule(prevScheduleData => ({
      ...prevScheduleData,
      ...updatedScheduleData,
    }));
  };

  useEffect(() => {
    if (open && selectedJob?.id) {
      getFacebookPosts({ variables: { jobId: selectedJob.id } });
    }
  }, [open, selectedJob, generatedText, generatedImage]);

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <ScheduleFacebookPostStep1
            fbPosts={fbPosts}
            generatedText={generatedText}
            imageLoading={imageGenerationLoading}
            textLoading={textGenerationLoading}
            setTextLoading={setTextLoading}
            setImageLoading={setImageLoading}
            generateFbPost={generateFbPost}
            selectedJob={selectedJob}
            getFbPostLoading={getFbPostLoading}
            onCancel={handleShowCloseConfirmDialog}
            setFbPosts={setFbPosts}
            handleMoveToNextStep={handleMoveToNextStep}
          />
        );
      case 1:
        return (
          <ScheduleFacebookPostStep2
            fbPosts={fbPosts}
            postSchedule={postSchedule}
            onSubmit={saveChanges}
            selectedJob={selectedJob}
            handleMoveToNextStep={handleMoveToNextStep}
            handlePreviousButtonClick={handleMoveToPreviousStep}
          />
        );
      case 2:
        return (
          <ScheduleFacebookPostStep3
            postSchedule={postSchedule}
            onSubmit={saveChanges}
            handleMoveToNextStep={handleMoveToNextStep}
            handlePreviousButtonClick={handleMoveToPreviousStep}
          />
        );
      case 3:
        return (
          <ConfirmFacebookPostSchedule
            fbPosts={fbPosts}
            postSchedule={postSchedule}
            handleClose={handleClose}
            handleMoveToNextStep={handleMoveToNextStep}
            handlePreviousButtonClick={handleMoveToPreviousStep}
            setSchedulePosts={setSchedulePosts}
            scheduleFacebookPost={scheduleFacebookPost}
          />
        );
      case 4:
        return <ThankYouPage handleClose={handleClose} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleShowCloseConfirmDialog}
        aria-labelledby="form-dialog-title"
        fullScreen>
        <DialogContent>
          <Box display="flex" justifyContent="flex-end" onClick={handleShowCloseConfirmDialog}>
            <Close style={{ cursor: 'pointer' }} />
          </Box>
          <Box pt={3} pb={8}>
            <Grid container justifyContent="center">
              <Grid item xs={6} lg={6}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {STEPS.map(label => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <Card>
                  <Box p={4}>
                    <Box>{getStepContent()}</Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={isConfirmDialogOpen} onClose={() => setIsConfirmDialogOpen(false)}>
        <DialogTitle>
          <Trans>jobPage.createJobForm.confirmCloseTitle</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText color="textPrimary">
            <Trans>jobPage.createJobForm.confirmCLoseText</Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmDialogOpen(false)}>
            <Trans>generic.no</Trans>
          </Button>
          <Button onClick={handleClose} variant={BUTTON_VARIANT.GRADIENT} color={BUTTON_COLOR.DARK}>
            <Trans>generic.yes</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { Button } from 'src/components/Button';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import colors from 'src/theme/base/colors';
import { ScheduleFacebookPostStep3Props, GroupData, PageData } from './types';
import boxShadows from 'src/theme/base/boxShadows';
import { Input } from 'src/components/Input';
import { TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { PageOptions, GroupOptions } from 'src/DTO/FacebookPost.type';
import { setPageGroupData } from 'src/utils/facebookPostUtils';

export const ScheduleFacebookPostStep3: React.FC<ScheduleFacebookPostStep3Props> = ({
  onSubmit,
  handlePreviousButtonClick,
  postSchedule,
  handleMoveToNextStep,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [activeDropdown, setActiveDropdown] = useState<string>('');

  const [pageOptions, setPageOptions] = useState<string[]>(
    postSchedule?.pageData?.map(post => post?.name) ?? Object.values(PageOptions),
  );
  const [groupOptions, setGroupOptions] = useState<string[]>(
    postSchedule?.groupData?.map(group => group?.name) ?? Object.values(GroupOptions),
  );

  const [inputValue, setInputValue] = useState<string>('');
  const [inputURL, setInputURLValue] = useState<string>('');

  const [pageData, setPageData] = useState<PageData[]>(() => postSchedule?.pageData ?? []);
  const [groupData, setGroupData] = useState<GroupData[]>(() => postSchedule?.groupData ?? []);

  const [selectedPages, setSelectedPages] = useState<string[]>(
    () => pageData?.map(page => page?.name) ?? [],
  );
  const [selectedGroups, setSelectedGroups] = useState<string[]>(
    () => groupData?.map(group => group?.name) ?? [],
  );

  const handlePageChange = (event: SelectChangeEvent<string[]>) => {
    setPageGroupData(event, setSelectedPages, pageData, setPageData, inputURL);
  };

  const handleGroupChange = (event: SelectChangeEvent<string[]>) => {
    setPageGroupData(event, setSelectedGroups, groupData, setGroupData, inputURL);
  };

  const handleClickOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
    setInputValue('');
    setInputURLValue('');
  };

  const handleSubmit = () => {
    onSubmit({
      ...postSchedule,
      pageData,
      groupData,
    });
    handleMoveToNextStep();
  };

  const handleSave = () => {
    if (inputValue && !pageOptions.includes(inputValue)) {
      if (activeDropdown === 'page') {
        setPageOptions(prev => [...prev, inputValue]);
        setSelectedPages(prev => [...prev, inputValue]);
        if (inputURL && inputValue) {
          setPageData(prev => [...prev, { name: inputValue, url: inputURL }]);
        }
      }
    }
    if (inputValue && !groupOptions.includes(inputValue)) {
      if (activeDropdown === 'group') {
        setGroupOptions(prev => [...prev, inputValue]);
        setSelectedGroups(prev => [...prev, inputValue]);
        if (inputURL && inputValue) {
          setGroupData(prev => [...prev, { name: inputValue, url: inputURL }]);
        }
      }
    }
    setIsDialogOpen(false);
    setInputValue('');
    setInputURLValue('');
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box mb={4} display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h5">
            <Trans>Add meg hogy hova posztoljunk</Trans>
          </Typography>
        </Box>
        <Box
          mb={3}
          p={2}
          border={`1px solid ${colors.inputColors.borderColor.main}`}
          borderRadius={2}
          boxShadow={boxShadows.sm}
          bgcolor="white">
          <Typography variant="h6" align="center" gutterBottom>
            <Trans>Mely oldalakra posztoljunk?</Trans>
          </Typography>
          <FormControl fullWidth variant="outlined" size="small" sx={{ width: '100%' }}>
            <Select
              multiple
              sx={{ width: '100%' }}
              value={selectedPages}
              onChange={handlePageChange}
              renderValue={selected => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  {selected?.map(value => {
                    const page = pageData.find(page => page?.name === value);
                    return (
                      <Tooltip
                        placement="top"
                        enterDelay={100}
                        leaveDelay={300}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: 'rgba(122, 191, 193, 0.5)',
                              color: 'white',
                              borderRadius: '8px',
                              padding: '8px 16px',
                              backdropFilter: 'blur(15px)',
                              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                              maxWidth: '400px',
                            },
                          },
                        }}
                        key={value}
                        title={
                          <Typography
                            variant="h6"
                            fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
                            component={Link}
                            href={page?.url}
                            target="_blank"
                            rel="noopener noreferrer">
                            {page?.url || 'Nincs megadva URL'}
                          </Typography>
                        }
                        arrow>
                        <Chip
                          sx={{
                            padding: 2,
                            margin: 1,
                            '&:hover': {
                              backgroundColor: '#17c1e8',
                            },
                          }}
                          label={value}
                        />
                      </Tooltip>
                    );
                  })}
                </Box>
              )}>
              {pageOptions?.map(option => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={selectedPages?.includes(option)} />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box mt={2}>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.DARK}
              onClick={() => {
                setActiveDropdown('page');
                handleClickOpen();
              }}>
              <Trans>generic.add</Trans>
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box
          mb={3}
          p={2}
          border={`1px solid ${colors.inputColors.borderColor.main}`}
          borderRadius={2}
          boxShadow={boxShadows.sm}
          bgcolor="white">
          <Typography variant="h6" align="center" gutterBottom>
            <Trans>Mely csoportokba posztoljunk?</Trans>
          </Typography>
          <FormControl fullWidth variant="outlined" size="small">
            <Select
              multiple
              sx={{ width: '100%' }}
              value={selectedGroups}
              onChange={handleGroupChange}
              renderValue={selected => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  {selected?.map(value => {
                    const group = groupData?.find(group => group?.name === value);
                    return (
                      <Tooltip
                        placement="top"
                        enterDelay={100}
                        leaveDelay={300}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: 'rgba(122, 191, 193, 0.5)',
                              color: 'white',
                              borderRadius: '8px',
                              padding: '8px 16px',
                              backdropFilter: 'blur(15px)',
                              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                              maxWidth: '400px',
                            },
                          },
                        }}
                        key={value}
                        title={
                          <Typography
                            variant="h6"
                            fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
                            component={Link}
                            href={group?.url}
                            target="_blank"
                            rel="noopener noreferrer">
                            {group?.url || 'Nincs megadva URL'}
                          </Typography>
                        }
                        arrow>
                        <Chip
                          sx={{
                            padding: 2,
                            margin: 1,
                            '&:hover': {
                              backgroundColor: '#17c1e8',
                            },
                          }}
                          label={value}
                        />
                      </Tooltip>
                    );
                  })}
                </Box>
              )}>
              {groupOptions?.map(option => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={selectedGroups?.includes(option)} />
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box mt={2}>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.DARK}
              onClick={() => {
                setActiveDropdown('group');
                handleClickOpen();
              }}>
              <Trans>generic.add</Trans>
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between">
          <Button onClick={handlePreviousButtonClick}>
            <Trans>generic.back</Trans>
          </Button>
          <Button
            variant={BUTTON_VARIANT.GRADIENT}
            color={BUTTON_COLOR.DARK}
            type="submit"
            disabled={!selectedPages.length || !selectedGroups.length}
            onClick={handleSubmit}>
            <Trans>jobPage.createRoleForm.submitButtonLabel</Trans>
          </Button>
        </Box>
      </Grid>

      <Dialog keepMounted open={isDialogOpen} onClose={handleClose}>
        <DialogContent>
          <Box mb={3}>
            <Input
              placeholder="Név hozzáadása"
              value={inputValue}
              onChange={({ currentTarget }) => setInputValue(currentTarget.value)}
            />
          </Box>
          <Box>
            <Input
              type="url"
              placeholder="Link hozzáadása"
              value={inputURL}
              onChange={({ currentTarget }) => setInputURLValue(currentTarget.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Mégsem</Button>
          <Button
            variant={BUTTON_VARIANT.GRADIENT}
            color={BUTTON_COLOR.DARK}
            onClick={handleSave}
            disabled={!inputURL || !inputValue}>
            Mentés
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

import React from 'react';
import { Grid, Card, CardContent } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { DrivingLicenseTypes, JobTypes, GenderTypes } from '../Job.types';
import { JobInfoSection } from 'src/components/JobInfoSection/JobInfoSection';
import { JobDetailDataProps } from './JobDetailData.types';

export const JobDetailData: React.FC<JobDetailDataProps> = ({ job, englishJob }) => {
  const { t } = useTranslation();

  const shouldShowMaleLabel =
    job?.genderTypes?.length === 1 && job?.genderTypes?.includes(GenderTypes.Male);

  const shouldShowFemaleLabel =
    job?.genderTypes?.length === 1 && job?.genderTypes?.includes(GenderTypes.Female);

  const county = job?.role?.county
    ? `${job.role?.county} ${t('jobPage.createRoleForm.county').toLowerCase()}`
    : '';
  const location = [county, job?.role?.location].filter(Boolean).join(', ');

  return (
    <Grid container spacing={1} alignItems="stretch">
      <Grid item xs={6}>
        <Card sx={{ display: 'flex', height: '100%' }}>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.hoursPerMonth"
                  content={job?.weeklyHours || t('jobPage.createJobModal.notProvided')}
                />
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="generic.location"
                  content={location || t('jobPage.createJobModal.notProvided')}
                />
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobModal.jobType"
                  content={
                    job.jobType === JobTypes.LongtermJob ? (
                      <Trans>jobPage.createJobForm.longTermJob</Trans>
                    ) : (
                      <Trans>jobPage.createJobForm.shortTermJob</Trans>
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobModal.schedule"
                  content={
                    englishJob
                      ? job?.role?.localizedData?.en?.timeSchedule ||
                        t('jobPage.createJobModal.notProvided')
                      : job?.timeSchedule || t('jobPage.createJobModal.notProvided')
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobModal.workerCount"
                  content={job?.workerCount || t('jobPage.createJobModal.notProvided')}
                />
              </Grid>
              <Grid item xs={6}>
                <Box mb={2}>
                  {job?.genderTypes?.length === 2 && (
                    <Typography variant="subtitle2">
                      <Trans>jobPage.createJobForm.girlsAndBoys</Trans>
                    </Typography>
                  )}
                  {shouldShowMaleLabel && (
                    <Typography variant="subtitle2">
                      <Trans>jobPage.createJobForm.boys</Trans>
                    </Typography>
                  )}
                  {shouldShowFemaleLabel && (
                    <Typography variant="subtitle2">
                      <Trans>jobPage.createJobForm.girls</Trans>
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobForm.startNow"
                  content={job?.startNow ? <Trans>generic.yes</Trans> : <Trans>generic.no</Trans>}
                />
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobForm.language"
                  content={
                    job?.hungarianSkillsNeeded || job?.englishSkillsNeeded ? (
                      <>
                        {job?.hungarianSkillsNeeded && (
                          <>
                            <Trans>jobPage.createJobModal.hungarian</Trans>
                            {job?.englishSkillsNeeded && <>, </>}
                          </>
                        )}
                        {job?.englishSkillsNeeded && (
                          <>
                            <Trans>jobPage.createJobModal.english</Trans>
                          </>
                        )}
                      </>
                    ) : (
                      t('jobPage.createJobModal.notProvided')
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobForm.documents"
                  content={
                    englishJob
                      ? job?.role?.localizedData?.en?.documents ||
                        t('jobPage.createJobModal.notProvided')
                      : job?.documents || t('jobPage.createJobModal.notProvided')
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobForm.extraSkills"
                  content={
                    englishJob
                      ? job?.role?.localizedData?.en?.extraSkills ||
                        t('jobPage.createJobModal.notProvided')
                      : job?.extraSkills || t('jobPage.createJobModal.notProvided')
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card sx={{ display: 'flex', height: '100%' }}>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobForm.drivingLicense"
                  content={
                    job?.drivingLicense === DrivingLicenseTypes.Car ? (
                      <Trans>jobPage.createJobForm.car</Trans>
                    ) : job?.drivingLicense === DrivingLicenseTypes.MotorCycle ? (
                      <Trans>jobPage.createJobForm.motorCycle</Trans>
                    ) : (
                      <Trans>jobPage.createJobForm.noLicense</Trans>
                    )
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobForm.bonus"
                  content={
                    englishJob
                      ? job?.role?.localizedData?.en?.bonus ||
                        t('jobPage.createJobModal.notProvided')
                      : job?.bonus || t('jobPage.createJobModal.notProvided')
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobForm.contactPerson"
                  content={job?.contactName || t('jobPage.createJobModal.notProvided')}
                />
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobForm.contactPhone"
                  content={job?.contactPhone || t('jobPage.createJobModal.notProvided')}
                />
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobForm.contactEmail"
                  content={job?.contactEmail || t('jobPage.createJobModal.notProvided')}
                />
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobForm.arriveInfo"
                  content={
                    englishJob
                      ? job?.role?.localizedData?.en?.arriveInfo ||
                        t('jobPage.createJobModal.notProvided')
                      : job?.arriveInfo || t('jobPage.createJobModal.notProvided')
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobForm.equipmentInfo"
                  content={
                    englishJob
                      ? job?.role?.localizedData?.en?.equipmentInfo ||
                        t('jobPage.createJobModal.notProvided')
                      : job?.equipmentInfo || t('jobPage.createJobModal.notProvided')
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <JobInfoSection
                  label="jobPage.createJobModal.recruitmentType"
                  content={
                    job?.recruitmentNeeded ? <Trans>generic.yes</Trans> : <Trans>generic.no</Trans>
                  }
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

import { FacebookPost } from 'src/DTO/FacebookPost.type';
import { useApi } from './useApi';
import { BaseHookRequest, BaseRequest } from './useApi.types';
import {
  FacebookPostResponse,
  FacebookPostPayload,
  UpdateFacebookPostPayload,
  UpdateScheduledFacebookPostPayload,
  ScheduleFacebookPostPayload,
  GetScheduledFacebookPostsResponse,
  GetScheduledFacebookPostsPayload,
} from './useFacebookPost.types';

export const useGetFacebookPosts: BaseHookRequest<FacebookPostResponse, FacebookPostPayload> =
  params => {
    const [{ getRequest }, state] = useApi<FacebookPostResponse>(params);

    const getFacebookPosts: BaseRequest<FacebookPostPayload> = details => {
      return getRequest(`/manager/job/facebook/posts/${details?.variables?.jobId}`);
    };

    return [getFacebookPosts, state];
  };

export const useUpdateFacebookPost: BaseHookRequest<FacebookPost, UpdateFacebookPostPayload> =
  params => {
    const [{ postRequest }, state] = useApi<FacebookPost>(params);
    const updateFacebookPost: BaseRequest<UpdateFacebookPostPayload> = details => {
      return postRequest<UpdateFacebookPostPayload>('/manager/job/facebook/post/update', {
        updatedFacebookPost: details?.variables.updatedFacebookPost,
      });
    };
    return [updateFacebookPost, state];
  };

export const useUpdatePostSchedule: BaseHookRequest<
  FacebookPost,
  UpdateScheduledFacebookPostPayload
> = params => {
  const [{ postRequest }, state] = useApi<FacebookPost>(params);
  const updatePostSchedule: BaseRequest<UpdateScheduledFacebookPostPayload> = details => {
    return postRequest<UpdateScheduledFacebookPostPayload>(
      '/manager/job/facebook/post/schedule/update',
      {
        scheduledPostData: details?.variables.scheduledPostData,
      },
    );
  };
  return [updatePostSchedule, state];
};

export const useScheduleFacebookPost: BaseHookRequest<FacebookPost, ScheduleFacebookPostPayload> =
  params => {
    const [{ postRequest }, state] = useApi<FacebookPost>(params);
    const scheduleFacebookPost: BaseRequest<ScheduleFacebookPostPayload> = details => {
      return postRequest<ScheduleFacebookPostPayload>('/manager/job/facebook/post/schedule', {
        postSchedule: details?.variables?.postSchedule,
      });
    };
    return [scheduleFacebookPost, state];
  };

export const useGetScheduledFacebookPosts: BaseHookRequest<
  GetScheduledFacebookPostsResponse,
  GetScheduledFacebookPostsPayload
> = params => {
  const [{ getRequest }, state] = useApi<GetScheduledFacebookPostsResponse>(params);
  const getScheduledFbPosts: BaseRequest<GetScheduledFacebookPostsPayload> = () => {
    getRequest('/manager/job/facebook/post/scheduled');
  };
  return [getScheduledFbPosts, state];
};

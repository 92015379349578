/* eslint-disable indent */
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Trans } from 'react-i18next';

import { TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { Button } from 'src/components/Button';
import { BUTTON_VARIANT, BUTTON_COLOR } from 'src/components/Button/types';
import { PostScheduleStatus, UpdateFacebookPostScheduleProps } from './types';
import { PostFrequency } from 'src/DTO/FacebookPost.type';
import { calculateDateDetails } from 'src/utils/facebookPostUtils';

export const UpdateFacebookPostSchedule: React.FC<UpdateFacebookPostScheduleProps> = ({
  setPostDetails,
  selectedPostSchedule,
  isScheduleUpdateDialogOpen,
  setIsScheduleUpdateDialogOpen,
  updateScheduledPost,
}) => {
  const [updatedSchedule, setUpdatedSchedule] = useState({
    startTime: '',
    startDate: '',
    endDate: '',
    frequency: PostFrequency.DAILY,
  });

  const { currentDate, calculatedEndDate } = calculateDateDetails(updatedSchedule?.startDate);

  useEffect(() => {
    if (selectedPostSchedule) {
      setUpdatedSchedule(prev => ({
        ...prev,
        startTime: selectedPostSchedule?.startTime || '',
        startDate: selectedPostSchedule?.startDate || '',
        endDate: selectedPostSchedule?.endDate || '',
        frequency: selectedPostSchedule?.frequency || PostFrequency.DAILY,
      }));
    }
  }, [selectedPostSchedule]);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStartDate = event.target.value || '';
    setUpdatedSchedule(prev => ({
      ...prev,
      startDate: newStartDate,
      endDate: '',
    }));
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = event.target.value || '';
    setUpdatedSchedule(prev => ({
      ...prev,
      endDate: newEndDate,
    }));
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStartTime = event.target.value || '';
    setUpdatedSchedule(prev => ({
      ...prev,
      startTime: newStartTime,
    }));
  };

  const handleFrequencyChange = (event: SelectChangeEvent<PostFrequency>) => {
    const newFrequency = (event.target.value as PostFrequency) || PostFrequency.DAILY;
    setUpdatedSchedule(prev => ({
      ...prev,
      frequency: newFrequency,
    }));
  };

  const handleClose = () => {
    setIsScheduleUpdateDialogOpen(false);
  };

  const handleSubmit = () => {
    const updatedDetails = {
      frequency: updatedSchedule?.frequency,
      startDate: updatedSchedule?.startDate,
      endDate: updatedSchedule?.endDate,
      startTime: updatedSchedule?.startTime,
    };
    setPostDetails(prevDetails => {
      return prevDetails.map(post =>
        post?.jobId === selectedPostSchedule?.jobId
          ? {
              ...post,
              ...updatedDetails,
            }
          : post,
      );
    });

    if (selectedPostSchedule?.jobId) {
      updateScheduledPost({
        variables: {
          scheduledPostData: {
            ...updatedDetails,
            jobId: selectedPostSchedule.jobId,
            status: PostScheduleStatus.INACTIVE,
          },
        },
      });
    }

    handleClose();
  };

  return (
    <Dialog
      open={isScheduleUpdateDialogOpen}
      fullWidth
      keepMounted
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '450px',
          padding: '16px',
        },
      }}>
      <DialogTitle>
        <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM} mb={2}>
          <Trans>Facebook poszt ütemezés szerkesztése</Trans>
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ paddingBottom: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
              Gyakoriság:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              sx={{ '.MuiInputBase-root': { padding: '0 !important', height: '35px !important' } }}>
              <Select
                IconComponent={ArrowDownwardIcon}
                value={updatedSchedule?.frequency}
                onChange={handleFrequencyChange}>
                {Object.values(PostFrequency).map(value => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
              Kezdési idő:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="time"
              value={updatedSchedule?.startTime}
              onChange={handleTimeChange}
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
              Kezdési dátum:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="date"
              inputProps={{
                min: currentDate,
              }}
              value={updatedSchedule?.startDate}
              onChange={handleDateChange}
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h6" fontWeight={TYPOGRAPHY_WEIGHT.BOLD}>
              Lejárati dátum:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="date"
              inputProps={{
                min: updatedSchedule.startDate,
                max: calculatedEndDate,
              }}
              value={updatedSchedule.endDate || ''}
              onChange={handleEndDateChange}
              size="small"
              aria-label="End date"
              fullWidth
              disabled={false}
            />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, mt: 3 }}>
          <Button onClick={handleClose}>
            <Trans>Mégsem</Trans>
          </Button>
          <Button
            variant={BUTTON_VARIANT.GRADIENT}
            color={BUTTON_COLOR.DARK}
            onClick={handleSubmit}
            disabled={false}>
            <Trans>Mentés</Trans>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

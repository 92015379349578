import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid, CircularProgress } from '@mui/material';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';
import { useGetInvitedWorkerRequest } from 'src/api/hooks/useWorker';
import { DataTable } from 'src/components/DataTable';
import { handleOpenWorkerProfile } from 'src/utils/workerUtils';
import { useWorkerColumns } from 'src/hooks/useWorkerColumns';

export const WorkersPage: React.FC = () => {
  const [getInvitedWorkers, { loading, data: invitedWorkers }] = useGetInvitedWorkerRequest();
  const { t } = useTranslation();

  const columns = useWorkerColumns({ t, handleOpenWorkerProfile });

  const [currentPage, setCurrentPage] = useState<number>(1);

  const shouldDisplayEmptyMessage = useMemo(
    () => !invitedWorkers || invitedWorkers?.finalizedWorkers?.length === 0,
    [invitedWorkers],
  );

  const handlePageChange = (newPage: React.SetStateAction<number>) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    getInvitedWorkers({
      variables: { currentPage },
    });
  }, [currentPage]);

  if (loading) {
    return (
      <Grid item xs={12}>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      {shouldDisplayEmptyMessage && (
        <Box display="flex" alignItems="center" pt={3}>
          <Typography>
            <Trans>workersScreen.invitedWorkerList.emptyMessage</Trans>
          </Typography>
        </Box>
      )}
      {invitedWorkers && !shouldDisplayEmptyMessage && (
        <DataTable
          canSearch
          table={{
            columns,
            rows: invitedWorkers.finalizedWorkers,
          }}
          changePage={newPage => handlePageChange(newPage)}
          workersCount={invitedWorkers.workersCount}
          totalPageCount={invitedWorkers.totalPageCount}
          currentPage={invitedWorkers.currentPage}
          title={t('workersScreen.invitedWorkerList.invitedWorkerList')}
        />
      )}
    </Grid>
  );
};
